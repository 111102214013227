import { useTranslation } from '@/hooks/translations';
import { getGstCurrencyType } from '@/common/utility';
import { ILayoutLocale } from './types';

export const useBillItemTranslation = (locale: string | null, currencyCode: string | null) => {
    const { t } = useTranslation('common');
    const getTrans = (key: string, defaultTrans?: string, locales?: ILayoutLocale[]) => {
        if (locale && locales && locales.length > 0) {
            const term = locales.find((o) => o.locale === locale);
            if (term) {
                return term.value;
            }
        }
        switch (key) {
            case 'def_subTotal':
            case 'sum_inclusive':
                return t('Sub Total');
            case 'def_tax':
                return t('Tax');
            case 'def_vat':
                return getGstCurrencyType(currencyCode);
            case 'def_qlubDiscount':
                return t('Qlub discount');
            case 'def_total':
            case 'def_payableAmount':
                return t('Your bill');
            case 'def_yourShare':
                return t('Your Share');
            case 'discount_inclusive':
                return t('Order-level discount');
            case 'fee_inclusive':
                return t('Order-level surcharge');
            case 'discount_exclusive':
                return t('Order-level excluded discount');
            default:
                return defaultTrans || key;
        }
    };
    return {
        getTrans,
    };
};

export const useOrderItemTranslation = (locale: string | null) => {
    const { t } = useTranslation('common');
    const getTrans = (key: string, defaultTrans?: string, locales?: ILayoutLocale[]) => {
        if (locale && locales && locales.length > 0) {
            const term = locales.find((o) => o.locale === locale);
            if (term) {
                return term.value;
            }
            return locales[0].value;
        }
        switch (key) {
            case 'def_order_topping':
                return t('Topping');
            default:
                return defaultTrans || key;
        }
    };
    return {
        getTrans,
    };
};
