// eslint-disable-next-line import/no-duplicates
import { tr, enAU, pt, ja, ar, zhCN, es } from 'date-fns/locale';
import {
    endOfDay,
    endOfMonth,
    startOfDay,
    startOfMonth,
    parseISO,
    // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
import { type ITimeRangeForm, TimeRangeTypes } from './types';

export const getLocaleLang = (lang: string) => {
    switch (lang) {
        case 'tr':
            return tr;
        case 'pt':
            return pt;
        case 'ja':
            return ja;
        case 'ar':
            return ar;
        case 'zh':
            return zhCN;
        case 'es':
            return es;
        default:
            return enAU;
    }
};

export const formatISO = (date: Date) => {
    return date.toISOString();
};

export const parseDate = (date: string) => {
    if (date.toLowerCase().includes('invalid')) {
        return new Date();
    }
    return new Date(date);
};

export const formatISOTimezone = (dateISO: string) => {
    const date = parseISO(dateISO);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

export const getDataRangeFromFilter = (
    dateRange: ITimeRangeForm,
): { from: string | null; to: string | null } | null => {
    const date = new Date();
    switch (dateRange.type) {
        case TimeRangeTypes.CUSTOM:
            return {
                from: dateRange.from,
                to: dateRange.to,
            };
        case TimeRangeTypes.TODAY:
            return {
                from: formatISO(startOfDay(date)),
                to: formatISO(endOfDay(date)),
            };
        case TimeRangeTypes.MONTH:
            return {
                from: formatISO(startOfMonth(date)),
                to: formatISO(endOfMonth(date)),
            };
        default:
            return null;
    }
};
