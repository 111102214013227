import { ReactNode, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableCell,
    TableRow,
    CircularProgress,
} from '@mui/material';
import { Breakpoint } from '@mui/system';
import { useTranslation } from '@/hooks/translations';
import { getCurrencyWithAmount } from '@/common/utility';
import RefundService from '@/services/refundService';
import { useSnackbar } from 'notistack';
import { useRestaurantContext } from '@/contexts/restaurant';
import { IRefundData } from '@/views/Payments';
import { onPushEvent } from '@/services/lib/gtm';
import { Cell, PrimaryButton, TableContent } from './common';

interface IProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    actionText?: string;
    children: ReactNode;
    maxWidth?: Breakpoint | false;
    actionMethod?: () => void;
    disableButton?: boolean;
    refundAmount: number;
    currencySymbol: string;
    currencyCode: string;
    refundReason: string;
    data: IRefundData;
    refresh: () => void;
}

export default function CustomRefundModal({
    open,
    onClose,
    title,
    actionText,
    children,
    maxWidth,
    disableButton,
    refundAmount,
    currencySymbol,
    currencyCode,
    refundReason,
    data,
    refresh,
}: IProps) {
    const { t } = useTranslation('common');
    const refundService = RefundService.getInstance();
    const { restaurant } = useRestaurantContext();
    const [openConfirm, setOpenConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpenConfirm(false);
    };

    const handleOpen = () => {
        onPushEvent('user_refund_config_confirmed');
        setOpenConfirm(true);
    };

    const handleRefund = () => {
        onPushEvent('user_refund_configured_refund_approved');
        setDisabled(true);
        setLoading(true);
        refundService
            .makeRefund({
                amount: refundAmount,
                countryCode: data?.countryCode,
                gatewayVendor: data?.gatewayVendor || '',
                reference: data?.reference || '',
                refundReason,
                restaurantUnique: data?.restaurantUnique,
                paymentRecordId: data?.paymentRecordId || '',
                restaurantId: restaurant?.id || '',
            })
            .then(() => {
                handleClose();
                refresh();
                onClose();
                enqueueSnackbar(t('Refund successful'), { variant: 'success' });
            })
            .catch((err) => {
                enqueueSnackbar(t(err.message), { variant: 'error' });
            })

            .finally(() => {
                setDisabled(false);
                setLoading(false);
                handleClose();
            });
    };
    return (
        <Dialog onClose={onClose} open={open} fullWidth maxWidth={maxWidth || 'md'}>
            {!!title && <DialogTitle sx={{ fontSize: '30px' }}>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <Dialog
                open={openConfirm}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{loading ? t('Processing Refund') : t('Are you sure to make refund?')}</DialogTitle>
                {loading ? (
                    <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <TableContent
                            large
                            headCells={
                                <>
                                    <TableCell>{t('Refund Amount')}</TableCell>
                                    <TableCell>{t('Refund Reason')}</TableCell>
                                </>
                            }
                        >
                            <TableRow>
                                <Cell>{getCurrencyWithAmount(refundAmount, currencySymbol, currencyCode)}</Cell>
                                <Cell>{t(refundReason)}</Cell>
                            </TableRow>
                        </TableContent>
                    </DialogContent>
                )}
                {!loading && (
                    <DialogActions>
                        <PrimaryButton
                            styles={{ backgroundColor: '#C4C4C4' }}
                            color="grey"
                            onClick={handleClose}
                            text={t('Cancel')}
                            disabled={disabled}
                        />
                        <PrimaryButton text={t('Confirm')} onClick={handleRefund} disabled={disabled} />
                    </DialogActions>
                )}
            </Dialog>
            {!!actionText && (
                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: ' 1rem 1.6rem 1.6rem 1.6rem',
                        backgroundColor: '#F8F8F8',
                    }}
                >
                    <PrimaryButton
                        id="cancel-btn"
                        styles={{ backgroundColor: '#C4C4C4' }}
                        color="grey"
                        onClick={onClose}
                        text={t('Cancel')}
                    />
                    <PrimaryButton id="action-btn" onClick={handleOpen} text={actionText} disabled={disableButton} />
                </DialogActions>
            )}
        </Dialog>
    );
}
