import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { format } from 'date-fns';
import { Theme, useMediaQuery } from '@mui/material';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import DateRange from '@mui/icons-material/DateRange';

import CommonModal from '@/components/CommonModal';
import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';

import { timeRangeTitles } from '../Form/hooks/useData';
import { type TimeRangeFormikProps, TimeRangeTypes } from '../Form/types';
import TimeRangeFormik from '../Formik';
import { getLocaleLang } from '../Form/utils';

type TimeDrawerProps = {
    customButton?: JSX.Element;
    noButton?: boolean;
    openState?: [boolean, Dispatch<SetStateAction<boolean>>];
} & TimeRangeFormikProps;

const TimeDrawer = ({ customButton, noButton, onConfirm, openState, options, ...rest }: TimeDrawerProps) => {
    const { t } = useTranslation('common');
    const { query } = useRouter();
    const lang = query.lang as string;
    const titles = timeRangeTitles(t);

    const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'), {
        defaultMatches: true,
        noSsr: false,
    });

    const [open, setOpen] = openState || useState<boolean>(false);
    const [buttonTitle, setButtonTitle] = useState(
        titles[rest.initialValue?.type || options?.[0] || TimeRangeTypes.TODAY],
    );

    const handleClose = () => {
        setOpen(false);
        onPushEvent('choosing_date_range_cancel');
    };

    return (
        <>
            {!noButton &&
                (customButton || (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setOpen(true);
                            onPushEvent('date_range_drawer_opened');
                        }}
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            height: '40px',
                            width: '100%',
                        }}
                    >
                        {buttonTitle}
                        <DateRange sx={{ marginLeft: '4px' }} />
                    </Button>
                ))}
            <Drawer
                PaperProps={{
                    sx: {
                        borderStartStartRadius: 32,
                        ...(md ? { borderEndStartRadius: 32, maxWidth: 500 } : { borderStartEndRadius: 32 }),
                    },
                }}
                open={open}
                anchor={md ? 'right' : 'bottom'}
                onClose={handleClose}
                keepMounted
            >
                <CommonModal onClose={handleClose} title={t('Date Range')}>
                    <TimeRangeFormik
                        {...rest}
                        onConfirm={(value) => {
                            onConfirm?.(value);
                            const { type, from, to } = value;
                            let title = timeRangeTitles(t)[type];
                            if (type === 'custom' && from && to) {
                                title = `${format(new Date(from), 'LLLL dd', {
                                    locale: getLocaleLang(lang),
                                })}-${format(new Date(to), 'LLLL dd', { locale: getLocaleLang(lang) })}`;
                            }
                            setButtonTitle(title);
                            setOpen(false);
                            onPushEvent(`user_use_filter_date_${value.type}`);
                        }}
                        options={options}
                    />
                </CommonModal>
            </Drawer>
        </>
    );
};

export default TimeDrawer;
