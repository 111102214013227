import { saveAs } from 'file-saver';
import * as Yup from 'yup';

import { IExportProductPayload } from '@/services/menuService/types';
import MenuService from '@/services/menuService/menuService';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';

import { TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { formatISOTimezone } from '@/components/TimeRange/Form/utils';
import ExportModal from '@/components/ExportModal';
import { commonExportInitialValues } from '@/components/ExportModal/data';
import { FileFormats } from '@/components/ExportModal/types';

export enum ReportTypes {
    ORDER = 'order',
    PRODUCT = 'product',
}

export default function QsrExportModal() {
    const menuService = MenuService.getInstance();
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();

    const saveFile = (data: Blob, name: string) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, name);
    };

    const getDateQuery = ({ type, from, to }: { type: TimeRangeTypes; from: string | null; to: string | null }) => {
        if (type === 'all' || !from || !to) {
            return {
                startDate: undefined,
                endDate: undefined,
                type: undefined,
            };
        }

        return {
            startDate: formatISOTimezone(from),
            endDate: formatISOTimezone(to),
            type,
        };
    };

    const handleSubmit = async (values: {
        reportType: ReportTypes;
        fileFormat: FileFormats;
        type: TimeRangeTypes;
        from: string;
        to: string;
    }) => {
        const { from, to, reportType, type, fileFormat } = values;
        const { endDate, startDate } = getDateQuery({ from, to, type });

        const id = restaurant?.id;
        let request = menuService.exportOrderReport;

        const body: IExportProductPayload = { fileFormat };
        body.endDate = endDate;
        body.startDate = startDate;

        if (reportType === ReportTypes.PRODUCT) {
            request = menuService.exportProductReport;
        }

        await request(id || '', body)
            .then((response) => {
                saveFile(
                    response.data,
                    `${reportType}-${restaurant?.country_code}-${restaurant?.restaurant_unique}.${fileFormat}`,
                );
            })
            .catch((err) => console.log(err));
    };

    return (
        <ExportModal
            buttonType="new"
            extraValidations={{
                reportType: Yup.string()
                    .oneOf(Object.values(ReportTypes), t('Please select one of the given values'))
                    .required(t('Report type is required')),
            }}
            initialValues={{
                ...commonExportInitialValues,
                reportType: ReportTypes.ORDER,
            }}
            onConfirm={handleSubmit}
            reportTypes={[
                { label: t('Order'), value: ReportTypes.ORDER },
                { label: t('Product'), value: ReportTypes.PRODUCT },
            ]}
            timeSelectEnabled
            title={t('Export Product Report')}
        />
    );
}
