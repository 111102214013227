import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useLanguageQuery } from 'next-export-i18n';
import DataTable from 'react-data-table-component';
import { useReactToPrint } from 'react-to-print';
import { unionWith } from 'lodash';

import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from '@/hooks/translations';
import useDataTable from '@/hooks/useDataTable';
import EventBus from '@/config/event-handler';
import { routes } from '@/constants/routes';
import { restrictRoles } from '@/constants/roleRestrictions';
import { vendorPaymentEvent } from '@/contexts/event.const';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import handleDownloadPdf from '@/common/downloadPdf';
import { covertUtcToRestaurantDateTime, getCurrencyWithAmount, getManyTotalByKey } from '@/common/utility';
import {
    IPaymentRecord,
    ITransaction,
    PaymentRecordType,
    parseTransactionNotification,
    IPaymentNotificationEvent,
} from '@/views/Payments/';

import OrdersService from '@/services/order';
import { getPaymentStatus } from '@/services/utils/order';
import { onPushEvent } from '@/services/lib/gtm';
import RestaurantService from '@/services/restaurant';
import { isTableTransactionMatch } from '@/services/utils/table';

import { OrdersTablePagination } from '@/components/Table/pagination';
import PrintableReceipt from '@/components/PrintableReceipt';
import PaymentReceipt from '@/components/PaymentReceipt';
import { formatISOTimezone, getDataRangeFromFilter } from '@/components/TimeRange/Form/utils';
import { Section } from '@/components/common';
import TicketModal from '@/components/TicketModal';
import TimeDrawer from '@/components/TimeRange/Drawer';
import { TimeRangeTypes } from '@/components/TimeRange/Form/types';

import { IPaymentTransaction, OrderByTypes, TableRequestData } from './types';
import { useFilters } from './useFilters';
import { useColumns } from './useColumns';
import { IRestaurantTables, ITableParams } from '../OrdersTableView/types';
import OrderExportModal from './export';

export const transformOrder = (
    transaction: ITransaction,
    lang: string,
    currencyCode: string,
    currencySymbol: string,
): IPaymentTransaction => {
    const date =
        lang !== 'ja'
            ? covertUtcToRestaurantDateTime(transaction.created_at, 'MM/DD/YYYY HH:mm a')
            : covertUtcToRestaurantDateTime(transaction.created_at, 'YYYY/MM/DD HH:mm');
    const status = getPaymentStatus(transaction);
    if (transaction.paymentRecord && transaction.paymentRecord.length > 0) {
        const [
            discount,
            voucher,
            paid,
            refundedAmount,
            tip,
            customerCommission,
            customerPaidExcludingDinerFee,
            loyalty,
        ] = getManyTotalByKey(
            transaction.paymentRecord,
            [
                'paid_amount',
                'paid_amount',
                'paid_amount',
                'paid_amount',
                'tip_amount',
                'customer_commission',
                'customer_paid_excluding_diner_fee',
                'paid_amount',
            ],
            [
                (pr: IPaymentRecord) =>
                    pr.type === PaymentRecordType.Discount && pr.status !== 'rejected' && pr.status !== 'initiated',
                (pr: IPaymentRecord) =>
                    pr.type === PaymentRecordType.Voucher && pr.status !== 'rejected' && pr.status !== 'initiated',
                (pr: IPaymentRecord) =>
                    (!pr.type ||
                        pr.type === PaymentRecordType.User ||
                        pr.type === PaymentRecordType.NonQlubUser ||
                        pr.type === PaymentRecordType.Terminal) &&
                    pr.status !== 'rejected' &&
                    pr.status !== 'initiated',
                (pr: IPaymentRecord) =>
                    pr.type === PaymentRecordType.Refund && pr.status !== 'rejected' && pr.status !== 'initiated',
                (pr: IPaymentRecord) => pr.status !== 'rejected' && pr.status !== 'initiated',
                (pr: IPaymentRecord) => pr.status !== 'rejected' && pr.status !== 'initiated',
                (pr: IPaymentRecord) => pr.status !== 'rejected' && pr.status !== 'initiated',
                (pr: IPaymentRecord) =>
                    pr.type === PaymentRecordType.Loyalty && pr.status !== 'rejected' && pr.status !== 'initiated',
            ],
        );
        const tipCommission = transaction.paymentRecord.reduce(
            (sum, entry) =>
                sum +
                (
                    entry.extra_info?.commissions?.flatMap((c) =>
                        c.category === 'tip' ? parseFloat(c.amount) || 0 : [],
                    ) || []
                ).reduce((a, b) => a + b, 0),
            0,
        );

        const total = tip + paid;
        const remaining =
            Number(transaction.bill_amount) +
            refundedAmount -
            (paid + discount + voucher + loyalty - customerCommission);
        return {
            ...transaction,
            order: {
                discount: getCurrencyWithAmount(discount, currencySymbol, currencyCode, true),
                voucher: voucher ? getCurrencyWithAmount(voucher, currencySymbol, currencyCode, true) : '-',
                loyalty: loyalty ? getCurrencyWithAmount(loyalty, currencySymbol, currencyCode, true) : '-',
                paid: getCurrencyWithAmount(paid - customerCommission, currencySymbol, currencyCode, true),
                refunded: getCurrencyWithAmount(refundedAmount, currencySymbol, currencyCode, true),
                netTip: getCurrencyWithAmount(tip - tipCommission, currencySymbol, currencyCode, true),
                grossTip: getCurrencyWithAmount(tip, currencySymbol, currencyCode, true),
                customerCommission: getCurrencyWithAmount(customerCommission, currencySymbol, currencyCode, true),
                customerPaid: getCurrencyWithAmount(total, currencySymbol, currencyCode, true),
                remaining: getCurrencyWithAmount(remaining, currencySymbol, currencyCode, true),
                bill: getCurrencyWithAmount(transaction.bill_amount, currencySymbol, currencyCode, true),
                date,
                status,
                customerPaidExcludingDinerFee: getCurrencyWithAmount(
                    customerPaidExcludingDinerFee,
                    currencySymbol,
                    currencyCode,
                    true,
                ),
            },
        };
    }
    return {
        ...transaction,
        order: {
            discount: '-',
            voucher: '-',
            loyalty: '-',
            paid: '-',
            refunded: '-',
            netTip: '-',
            grossTip: '-',
            customerCommission: '-',
            customerPaid: '-',
            remaining: '-',
            bill: getCurrencyWithAmount(transaction.bill_amount, currencySymbol, currencyCode, true),
            date,
            status,
            customerPaidExcludingDinerFee: '-',
        },
    };
};

export default function Orders() {
    const { t } = useTranslation('common');
    const restaurantService = RestaurantService.getInstance();
    const ordersService = OrdersService.getInstance();
    const { user } = useUserContext();
    const { restaurant } = useRestaurantContext();
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const [currencySymbol, setCurrencySymbol] = useState<string>('');
    const [currencyCode, setCurrencyCode] = useState<string>('');
    const [detailModal, setDetailModal] = useState(false);
    const [hasVoucher, setHasVoucher] = useState<boolean>(false);
    const [hasLoyalty, setHasLoyalty] = useState<boolean>(false);
    const [detailId, setDetailId] = useState<string | null>(null);
    const [showOrderInfo, setShowOrderInfo] = useState(false);
    const [filteredList, setFilteredList] = useState<IPaymentTransaction[]>([]);
    const router = useRouter();
    const [printDoc, setPrintDoc] = useState<IPaymentTransaction | undefined>(undefined);
    const [pdfDoc, setPdfDoc] = useState<IPaymentTransaction | undefined>(undefined);
    const [qrList, setQrList] = useState<ITableParams[]>();

    const showTip = !restaurant?.config?.disableTipForVendorUsers || false;
    const columns = useColumns({
        currencyCode,
        currencySymbol,
        showOrderInfo,
        setHasVoucher,
        setHasLoyalty,
        setDetailId,
        setDetailModal,
        setPrintDoc,
        setPdfDoc,
    }).filter((c) => {
        if (!showTip) {
            return c.key !== 'tip_amount';
        }
        return true;
    });
    const { selectedStatus, renderFilters, debouncedTableSearch, searchOrder, timeRange, setTimeRange } = useFilters();
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('lg'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const printRef = useRef<any>(undefined);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const getQrs = () => {
        restaurantService
            .getQrs(user.restaurantId)
            .then((res) => {
                setQrList(
                    unionWith(
                        res.rows.reverse().map((qr: IRestaurantTables) => qr.params),
                        (o1, o2) =>
                            o1.id === o2.id && (o1.f1 || '') === (o2.f1 || '') && (o1.f2 || '') === (o2.f2 || ''),
                    ),
                );
            })
            .catch(console.log);
    };

    useEffect(() => {
        onPushEvent('user_visit_orders_section');
        getQrs();
    }, []);

    const {
        loading,
        page,
        total,
        list,
        setList,
        setPage,
        getData,
        tableRowsPerPageChangeHandler,
        tablePageChangeHandler,
    } = useDataTable<IPaymentTransaction, OrderByTypes>({
        withSearch: true,
        dataFetcher: async (requestData: TableRequestData) => {
            try {
                const dateRange = getDataRangeFromFilter(timeRange);
                const res = await ordersService.getOrders(user.restaurantId, {
                    tableName: debouncedTableSearch,
                    orderId: searchOrder,
                    startDate: dateRange?.from ? formatISOTimezone(dateRange?.from.toString()) : null,
                    endDate: dateRange?.to ? formatISOTimezone(dateRange?.to.toString()) : null,
                    type: timeRange.type,
                    period: timeRange.type,
                    ...requestData,
                });
                setCurrencySymbol(res?.currency_symbol);
                setCurrencyCode(res?.currency_code);
                return {
                    total: res?.transactions?.count || 0,
                    list: (res?.transactions?.rows || []).map((trans: ITransaction) =>
                        transformOrder(trans, lang, res?.currency_code, res?.currency_symbol),
                    ),
                };
            } catch (error) {
                return {
                    list: [],
                    total: 0,
                };
            }
        },
    });

    const changePreferredName = (remoteList: ITransaction[]) => {
        const out: ITransaction[] = [];
        remoteList.forEach((item) => {
            const preferredName = qrList?.find((qr) => isTableTransactionMatch(qr, item))?.name || '';
            if (preferredName) {
                out.push({
                    ...item,
                    table_data: {
                        ...item.table_data,
                        name: preferredName,
                    },
                });
            } else {
                out.push(item);
            }
        });

        return out;
    };

    // useEffect(() => {
    //     if (qrList && filteredList.length > 0) {
    //         changePreferredName(filteredList);
    //     }
    // }, [qrList?.length, filteredList.length]);

    const transformData = (remoteList: ITransaction[]) => {
        setList(remoteList);
        setShowOrderInfo(remoteList.some((o) => o.pos_extra_info?.pos_order_id));
    };

    useEffect(() => {
        getData();
    }, [
        page,
        user,
        debouncedTableSearch,
        searchOrder,
        timeRange.type,
        timeRange.type === 'all' ? '' : timeRange.from,
        timeRange.type === 'all' ? '' : timeRange.to,
    ]);

    useEffect(() => {
        transformData(list);
    }, [list]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (page === 0) {
                getData();
            } else {
                setPage(1);
            }
        }, 300000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (!user || (user.userData.role || '').indexOf('Vendor') === -1) {
            return () => {
                //
            };
        }

        const fetchDataHandler = ({ detail: msgList }: IPaymentNotificationEvent) => {
            if (router.pathname === routes.ORDERS && page === 1) {
                setList((li) => {
                    return parseTransactionNotification(li, msgList).map((item) => {
                        if (msgList.find((o) => o.id === item.id)) {
                            return transformOrder(item, lang, currencyCode, currencySymbol);
                        }
                        return item;
                    });
                });
            }
        };

        EventBus.on(vendorPaymentEvent, fetchDataHandler);
        return () => {
            EventBus.remove(vendorPaymentEvent, fetchDataHandler);
        };
    }, [user, page, router.pathname, currencyCode, currencySymbol]);

    useEffect(() => {
        if (selectedStatus === 'Voucher') {
            const l = list.filter((o) => o.paymentRecord?.some((pr) => pr.type === PaymentRecordType.Voucher));
            setFilteredList(changePreferredName(l));
            setShowOrderInfo(l.some((o) => o.pos_extra_info?.pos_order_id));
        } else if (selectedStatus === 'Loyalty') {
            const l = list.filter((o) => o.paymentRecord?.some((pr) => pr.type === PaymentRecordType.Loyalty));
            setFilteredList(changePreferredName(l));
            setShowOrderInfo(l.some((o) => o.pos_extra_info?.pos_order_id));
        } else if (selectedStatus === 'Paid') {
            const l = list.filter(
                (o) =>
                    o.paid ||
                    (o.paymentRecord?.reduce(
                        (previousValue, currentValue) => Number(previousValue) + Number(currentValue.paid_amount),
                        0,
                    ) || 0) >= Number(o.order_data.amount),
            );
            setFilteredList(changePreferredName(l));
            setShowOrderInfo(l.some((o) => o.pos_extra_info?.pos_order_id));
        } else if (selectedStatus === 'Partially') {
            const l = list.filter(
                (o) =>
                    !o.paid &&
                    (o.paymentRecord?.reduce(
                        (previousValue, currentValue) => Number(previousValue) + Number(currentValue.paid_amount),
                        0,
                    ) || 0) < Number(o.order_data.amount),
            );
            setFilteredList(changePreferredName(l));
            setShowOrderInfo(l.some((o) => o.pos_extra_info?.pos_order_id));
        } else {
            setFilteredList(changePreferredName(list));
            setShowOrderInfo(list.some((o) => o.pos_extra_info?.pos_order_id));
        }
    }, [list, selectedStatus, qrList]);

    const expandableRowsComponent = (row: any) => {
        const omitTrueItems = columns.filter((c) => c.omit);

        return (
            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                {omitTrueItems.map((c, id) => {
                    if (c.key === 'pos_order_id' && !row.data.pos_extra_info?.pos_order_id) return null;

                    return (
                        <Grid item xs={12} key={id}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '0 10px',
                                }}
                            >
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {c.name}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                                    {(c.selector && c.selector(row.data)) || (c.cell && c.cell(row.data))}
                                </Typography>
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const printHandler = (ref: any) => {
        if (!ref) {
            return;
        }
        printRef.current = ref;
        handlePrint();
        setPrintDoc(undefined);
    };

    useEffect(() => {
        if (!pdfDoc) {
            return;
        }
        handleDownloadPdf(pdfDoc, () => {
            setPdfDoc(undefined);
        });
    }, [pdfDoc]);

    return (
        <>
            {printDoc && (
                <PrintableReceipt
                    payment={printDoc}
                    restaurant={restaurant}
                    currencySymbol={currencySymbol}
                    currencyCode={currencyCode}
                    innerRef={printHandler}
                    showTip={showTip}
                    t={t}
                />
            )}
            {pdfDoc && (
                <PaymentReceipt
                    payment={pdfDoc}
                    restaurant={restaurant}
                    currencySymbol={currencySymbol}
                    currencyCode={currencyCode}
                    showTip={showTip}
                    t={t}
                />
            )}

            <Grid
                container
                spacing={2}
                sx={{
                    marginBottom: '16px',
                    textAlign: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <Grid item xs={mobile ? 6 : undefined} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {restrictRoles.EXPORT_ORDERS_AND_TRANSACTIONS.includes(user?.userData?.role) && (
                        <OrderExportModal />
                    )}
                </Grid>
                <Grid item xs={mobile ? 6 : undefined}>
                    <TimeDrawer
                        initialValue={{ type: TimeRangeTypes.ALL, from: null, to: null }}
                        onConfirm={(val) => {
                            onPushEvent(`user_use_filter_date_${val.type}`);
                            setTimeRange(val);
                        }}
                        timeSelectEnabled
                    />
                </Grid>
            </Grid>
            <Section
                style={{
                    maxWidth: tablet ? '94vw' : 'unset',
                }}
            >
                <DataTable
                    customStyles={{
                        rows: {
                            style: {
                                borderBottom: 'none !important',
                            },
                        },
                        headRow: {
                            style: {
                                '& div': {
                                    whiteSpace: 'pre-wrap',
                                    justifyContent: 'flex-start',
                                },
                            },
                        },
                        headCells: {
                            style: {
                                justifyContent: mobile ? 'center' : undefined,
                            },
                        },
                        header: {
                            style: {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'stretch',
                                paddingLeft: '0px',
                                fontSize: '1.5rem',
                                fontWeight: '400',
                                ...(mobile && { textAlign: 'center' }),
                            },
                        },
                    }}
                    expandableRows={mobile}
                    expandableRowsComponent={(row) => {
                        return expandableRowsComponent(row);
                    }}
                    actions={renderFilters()}
                    fixedHeader
                    striped
                    columns={columns}
                    data={filteredList}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    onChangeRowsPerPage={tableRowsPerPageChangeHandler}
                    onChangePage={tablePageChangeHandler}
                    paginationComponent={OrdersTablePagination}
                    noDataComponent={
                        <Box
                            sx={{
                                width: '100%',
                                paddingRight: '1.2em',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    paddingRight: '1.2em',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '20px',
                                }}
                            >
                                {page === 0 ? t('No Order Found!') : t('No Order In This Page!')}
                            </Box>
                            <OrdersTablePagination
                                onChangePage={tablePageChangeHandler}
                                currentPage={page}
                                isNextButtonDisabled={filteredList.length === 0}
                            />
                        </Box>
                    }
                />
            </Section>
            <TicketModal
                id={detailId}
                restaurantId={user.restaurantId}
                currencySymbol={currencySymbol}
                currencyCode={currencyCode}
                open={detailModal}
                onClose={() => {
                    setDetailModal(false);
                }}
                showTip={showTip}
                hasVoucher={hasVoucher}
                hasLoyalty={hasLoyalty}
                setPdfDoc={setPdfDoc}
                setPrintDoc={setPrintDoc}
            />
        </>
    );
}
