export enum TipMode {
    Default = 'default',
    Fixed = 'fixed',
    Percentage = 'percentage',
}

export enum TipTooltipMode {
    Hide = '',
    Once = 'once',
    OncePerVendor = 'once_per_vendor',
    Always = 'always',
}

export enum DiscountType {
    Fixed = 'fixed',
    Percentage = 'percentage',
}

export enum SplitModeType {
    empty = '',
    byItem = 'byItem',
    byShare = 'byShare',
    custom = 'custom',
    unknown = 'unknown',
}

export interface IDiscountType {
    amount: string;
    type: DiscountType;
}

export interface IRestaurantPayload {
    restaurantUnique: string;
    cc: string;
}

export enum StyleEnum {
    TitleMedium = 'titleMedium',
    TitleSmall = 'titleSmall',
    BodyMedium = 'bodyMedium',
    BodySmall = 'bodySmall',
    BodyMediumStrike = 'bodyMediumStrike',
    BodySmallStrike = 'bodySmallStrike',
}

export enum IconEnum {
    Dollar = 'dollar',
    Yen = 'yen',
    Rial = 'rial',
    Percent = 'percent',
    Bill = 'bill',
    ItemPercent = 'itemPercent',
    ItemBill = 'itemBill',
    Discount = 'discount',
}

export enum ColorEnum {
    Primary = 'primary',
    Secondary = 'secondary',
    HighEmphasis = 'highEmphasis',
    MediumEmphasis = 'mediumEmphasis',
    LowEmphasis = 'lowEmphasis',
    Disabled = 'disabled',
    Background = 'background',
    Green = 'green',
}

export enum ConditionEnum {
    Default = '',
    GT = 'gt',
    GTE = 'gte',
    LT = 'lt',
    LTE = 'lte',
    NE = 'ne',
    E = 'e',
    GTD = 'gtd',
}

export type ILayoutLocale = {
    locale: string;
    value: string;
};

export interface ILayout {
    key: string;
    style?: StyleEnum | null;
    icon?: IconEnum | null;
    color?: ColorEnum | null;
    newFormat?: boolean | null;
    format?: string | null;
    vcond?: ConditionEnum | null;
    hideAltValue?: boolean;
    locales?: ILayoutLocale[];
}

export interface IKeyValue {
    key: string;
    value: any;
    layout?: ILayout;
    order?: number;
    title?: string;
    id?: string;
}

export interface IKeyValueOrder extends IKeyValue {
    qty?: string;
    altValue?: string;
    secondAltValue?: string;
    updatedAt?: number;
}

export interface IPaymentDetailsOrderItems {
    item: IKeyValueOrder;
    items: IKeyValueOrder[];
}

interface IPaymentDetailsBillNumber {
    qlubDiscount: number;
    paid: number;
    remaining: number;
    subTotal: number;
    tax: number;
    total: number;
    vat: number;
    yourShare: number;
    billAmount: number;
    payableAmount: number;
}

export interface IPaymentPartyNumber {
    amount: number;
    id: string;
    name: string;
    paymentDone?: boolean;
    splitMode?: string;
    paidAt?: number;
    nonQlub?: boolean;
    items?: IPaidItem[];
    share?: number;
    totalShares?: number;
}

export interface IPaymentSplit {
    availableModes: Array<SplitModeType>;
    mode: SplitModeType;
    totalShares: string;
    shareAmount: string;
}

export interface IPaymentSplitUI extends IPaymentSplit {
    totalSharesNumber: number;
    shareAmountNumber: number;
    conflict?: boolean;
}

export interface IPaymentDetails {
    _billItems: IKeyValue[];
    _orderItems: IPaymentDetailsOrderItems[];
    _layoutInit?: boolean;
    bill: IPaymentDetailsBill;
    billNumber: IPaymentDetailsBillNumber;
    billSplit?: IPaymentSplitUI;
    restaurantUnique: string;
    tableID: string;
    parties?: IPaymentPartyUI[];
    currency: string;
    diningSessionID: string;
    currencyPrecision: number;
    tableName?: string;
    jwt?: string;
    yourSplitSettings: IPaymentPartyUI;
    otherSplitSettings: IPaymentPartyUI;
}

export interface IAdditiveView {
    key: string;
    amount: string;
    value: string;
    category: 'tax' | 'discount' | 'fee' | 'sum';
    title: string;
}

export interface IToppingView {
    qty: string;
    title: string;
    unitPrice: string;
    updatedAt: number;
    subTotal?: string;
    finalPrice?: string;
}

export interface IPaidItem {
    id?: string;
    index: number;
    qty: number;
}

interface IPaymentDetailsBill {
    qlubDiscount: string;
    paid: string;
    remaining: string;
    subTotal: string;
    tax: string;
    total: string;
    vat: string;
    yourShare: string;
    additives?: Array<IAdditiveView>;
    billAmount?: string;
    payableAmount?: string;
    updatedAt: string;
}

export interface IOrderItemView {
    qty: string;
    title: string;
    unitPrice: string;
    updatedAt: number;
    subTotal?: string;
    finalPrice?: string;
    finalUnitPrice?: string;
    toppings?: Array<IToppingView>;
    additives?: Array<IAdditiveView>;
}

export interface IPaidItemString {
    id?: string;
    index: number;
    qty: string;
}

export interface IPartySplit {
    items?: Array<IPaidItemString>;
    meta?: any;
    splitMode: SplitModeType;
    share: string;
    totalShares: string;
}

export interface IPaymentParty {
    amount: string;
    id: string;
    name: string;
    paymentDone?: boolean;
    paidAt?: number;
    nonQlub?: boolean;
    billSplit: IPartySplit;
}

export interface IPartySplitUI {
    items?: Array<IPaidItem>;
    meta?: any;
    splitMode: SplitModeType;
    share: number;
    totalShares: number;
}

export interface IPaymentPartyUI {
    amount: string;
    amountNumber: number;
    id: string;
    name: string;
    paymentDone?: boolean;
    paidAt?: number;
    nonQlub?: boolean;
    billSplit: IPartySplitUI;
}

export interface IGetLayoutConfig {
    cc: string;
    bill?: ILayout[];
    item?: ILayout[];
}

export interface IGetPaymentDetailConfig extends IGetLayoutConfig {
    ignoreCache?: boolean;
}

export interface IPaymentDetailsResponse {
    bill: IPaymentDetailsBill;
    billSplit: IPaymentSplit;
    currency: string;
    currencyPrecision: number;
    orderItems?: IOrderItemView[];
    parties?: IPaymentParty[];
    restaurantUnique: string;
    tableID: string;
    diningSessionID: string;
    dsi?: string;
    url?: string;
    tableName: string;
    jwt?: string;
    abn: number;
}
