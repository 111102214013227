export enum TimeRangeTypes {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    WEEK = 'week',
    LAST_7_DAYS = 'last_7_days',
    MONTH = 'month',
    LAST_MONTH = 'last_month',
    LAST_30_DAYS = 'last_30_days',
    QUARTER = 'quarter',
    ALL = 'all',
    CUSTOM = 'custom',
}

export interface ITimeRangeForm {
    type: TimeRangeTypes;
    from: string | null;
    to: string | null;
}

export interface TimeRangeFormProps {
    minDate?: Date;
    options?: TimeRangeTypes[];
    timeSelectEnabled?: boolean;
}

export type TimeRangeFormikProps = {
    initialValue?: ITimeRangeForm;
    onClose?: () => void;
    content?: 'modal' | 'drawer';
} & TimeRangeFormProps &
    (
        | {
              useCustomFormik: any;
              onConfirm?: never;
          }
        | {
              onConfirm: (value: ITimeRangeForm) => void;
              useCustomFormik?: never;
          }
    );
