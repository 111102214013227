import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { useMemo } from 'react';
import { IPaymentDetailsResponse } from '../InvoiceTable/types';

export const transformData = (data: IPaymentDetailsResponse | null) => ({
    restaurantUnique: data?.restaurantUnique,
    tableID: data?.tableID || '',
    tableName: data?.tableName || '',
    diningSessionID: data?.diningSessionID || '',
    currency: data?.currency || 'AED',
    currencyPrecision: data?.currencyPrecision || getCurrencyPrecision('AED'),
    parties: data?.parties || [],
    orderItems: data?.orderItems || [],
    bill: data?.bill,
});

export interface ILocaleValue {
    locale: string;
    value: string;
}
export const parseJSON = (data: any) => {
    try {
        return typeof data === 'string' ? JSON.parse(data) : data;
    } catch {
        return undefined;
    }
};

export const convertMultiLocaleStringToObject = (
    value: string | Array<ILocaleValue>,
    defaultValue?: string,
): { [key: string]: string } => {
    if (!value) {
        return {
            default: defaultValue || '',
        };
    }
    try {
        const list: Array<ILocaleValue> = typeof value === 'string' ? parseJSON(value) : value;
        const localeMap = list.reduce<{ [key: string]: string }>((a, b) => {
            a[b.locale] = b.value;
            return a;
        }, {});
        if (!localeMap.hasOwnProperty('default')) {
            const def = list.find((o) => o.value !== '');
            if (def) {
                return {
                    default: def.value,
                    ...localeMap,
                };
            }
        }
        return localeMap;
    } catch {
        return {
            default: defaultValue || String(value),
        };
    }
};

export const validateMultiLocale = (value: string | Array<ILocaleValue>) => {
    const locales = convertMultiLocaleStringToObject(value);
    return Object.values(locales).some((o) => o);
};

export function MultiLocale({
    value,
    lang,
    Component,
}: {
    value: string | Array<ILocaleValue>;
    lang?: string | null;
    Component?: React.ElementType;
}) {
    const defaultLang = 'en';
    const lng = lang || defaultLang;

    const locales = useMemo<any>(() => {
        return convertMultiLocaleStringToObject(value);
    }, [value]);

    const content = useMemo(() => {
        return locales[lng] || locales.default || '';
    }, [locales, lng]);
    if (!content) {
        return null;
    }
    if (Component) {
        return <Component>{content}</Component>;
    }
    return content;
}
