import axios from '@/config/axios';
import { DownloadBrandTransactionParams, DownloadRestaurantTransactionParams } from '@/interfaces/transaction/types';
import { ICoreEdgeReceiptResponse } from '@/services/interface/transaction';
import { transformError } from '.';

const endpoints = {
    TRANSACTION: 'vendor/transaction/:restaurant_id',
    TRANSACTION_BY_ORDER_ID: 'vendor/table-session/:restaurant_id?orderId=:order_id',
    DOWNLOAD_RESTAURANT_TRANSACTION: 'vendor/transaction/download/:restaurant_id',
    DOWNLOAD_BRAND_TRANSACTION: 'vendor/transaction/brand/download/:brand_id',
    ORDER_SEARCH_TRANSACTION: 'vendor/transaction/order-search/:restaurant_id',
    TRANSACTION_TABLE_SESSION_ID: 'vendor/transaction/:restaurant_id/:table_session_id',
    RECEIPT_DATA: 'vendor/transaction/receipt/:restaurant_id/:table_session_id',
    SCAN_QR: 'vendor/transaction/scan_qr/:restaurant_id/:qr_id',
};

class TransactionService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new TransactionService();
        }

        return this.instance;
    }

    private static instance: TransactionService;

    public getTransaction(restaurantId: string, id: string) {
        return axios
            .get<any>(
                endpoints.TRANSACTION_TABLE_SESSION_ID.replace(':restaurant_id', restaurantId).replace(
                    ':table_session_id',
                    id,
                ),
            )
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getTransactions(restaurantId: string, paymentLinkId: string) {
        return axios
            .get<any>(
                `${endpoints.TRANSACTION_BY_ORDER_ID.replace(':restaurant_id', restaurantId).replace(
                    ':order_id',
                    paymentLinkId,
                )}`,
            )
            .then((res) => res.data?.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getReceiptData(restaurantId: string, id: string) {
        return axios
            .get<ICoreEdgeReceiptResponse>(
                endpoints.RECEIPT_DATA.replace(':restaurant_id', restaurantId).replace(':table_session_id', id),
            )
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public scanQR(restaurantId: string, qrId: string) {
        return axios
            .get<ICoreEdgeReceiptResponse>(
                endpoints.SCAN_QR.replace(':restaurant_id', restaurantId).replace(':qr_id', qrId),
            )
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public downloadRestaurantTransaction(restaurantId: string, params: DownloadRestaurantTransactionParams) {
        return axios({
            url: endpoints.DOWNLOAD_RESTAURANT_TRANSACTION.replace(':restaurant_id', restaurantId),
            method: 'GET',
            responseType: 'blob', // important
            params,
        }).catch((err) => {
            throw transformError(err);
        });
    }

    public downloadBrandTransaction(brandId: string, params: DownloadBrandTransactionParams) {
        return axios({
            url: endpoints.DOWNLOAD_BRAND_TRANSACTION.replace(':brand_id', brandId),
            method: 'GET',
            responseType: 'blob', // important
            params,
        }).catch((err) => {
            throw transformError(err);
        });
    }

    public orderSearch(restaurantId: string) {
        return axios({
            url: endpoints.ORDER_SEARCH_TRANSACTION.replace(':restaurant_id', restaurantId),
            method: 'GET',
        }).catch((err) => {
            throw transformError(err);
        });
    }
}

export default TransactionService;
