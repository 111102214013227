export const transformError = (err: any) => {
    const message = err?.response?.data?.message || null;
    if (!message) {
        return new Error(err.message);
    }
    if (Array.isArray(message)) {
        return new Error(message.join('\n'));
    }
    return new Error(message);
};
