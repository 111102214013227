import * as React from 'react';
import { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useUserContext } from '@/contexts/user';
import { AppBar, Toolbar, Box, useMediaQuery } from '@mui/material';
import { CustomButton, HeaderPageTitle } from '@/components/common';
import SettingsButton from '@/components/SettingsButton';
import { styled, SxProps } from '@mui/material/styles';
import { SocketConnector } from '@/contexts/socket';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useSidebarRoutes } from '@/hooks/useSiderbarRoutes';
import { routes as links } from '@/constants/routes';
import { onPushEvent } from '@/services/lib/gtm';
import { clearLocalStorage } from '@/config/axios';
import Head from 'next/head';
import EventBus from '@/config/event-handler';
import { VendorRole } from '@/constants/roleRestrictions';
import { CachedRounded } from '@mui/icons-material';
import { useTranslation } from 'next-export-i18n';
import { useNavbarContext } from '@/contexts/navbar';
import HamburgerMenuIcon from '../HamburgerMenuIcon';
import AccountMenu from './AccountMenu';
import LanguageSelectorWithFlags from './LanguageSelectorWithFlags';

const useNavbarStyles = () => {
    const navBar: SxProps = {
        display: 'flex',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr));',
    };

    const pageTitle: SxProps = {
        justifyContent: 'space-around',
    };

    const AvatarContainer: SxProps = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    };

    return { navBar, pageTitle, AvatarContainer };
};

const Nav = styled(AppBar)(({ theme }) => ({
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: '1px #E5E7EB solid',
}));

const tableViewPaths = [links.TABLE_VIEW, links.VPOS];
const qsrViewPaths = [links.QSR_ORDERS];

export default function Navbar() {
    const routes = useRouter();
    const { t } = useTranslation('common');
    const { toggleRefresh, refresh } = useNavbarContext();
    const { pathname } = routes;
    const { getRoutes } = useSidebarRoutes();
    const { user, setUser } = useUserContext();
    const { restaurant, setRestaurant } = useRestaurantContext();
    const { navBar, AvatarContainer } = useNavbarStyles();
    const logout = () => {
        setUser(null);
        setRestaurant(null);
        SocketConnector.getInstance().destroy();
        clearLocalStorage();
        EventBus.dispatch('logout');
        onPushEvent('user_logged_out');
    };
    const containerRef = useRef(null);

    const getTitle = () => {
        const routeToPageTitle = getRoutes(user);
        const routeArray = [...routeToPageTitle.general, ...routeToPageTitle.settings];
        return routeArray.find((route) => route.link === routes.pathname)?.text;
    };

    const [isTableView, withRefresh] = useMemo(() => {
        return [tableViewPaths.includes(pathname), qsrViewPaths.includes(pathname)];
    }, [pathname]);

    const enableShiftMaskforVendorAnalyst =
        (user?.userData?.role === VendorRole.VendorAnalyst && restaurant?.config?.enableShiftMaskforVendorAnalyst) ||
        false;

    const switchLanguageByFlag = restaurant?.config?.switchLanguageByFlag || false;

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(800), {
        defaultMatches: true,
        noSsr: false,
    });

    const MenuIconAndTitle = useMemo(() => {
        if (!enableShiftMaskforVendorAnalyst && isMobile) {
            return (
                <Box sx={{ display: 'flex' }}>
                    <HamburgerMenuIcon withLogo={false} />
                    <HeaderPageTitle text={getTitle()} />
                </Box>
            );
        }
        return <HeaderPageTitle text={getTitle()} />;
    }, [isMobile, enableShiftMaskforVendorAnalyst, routes.pathname]);

    return (
        <Nav position="static" ref={containerRef}>
            <Head>
                <title>{getTitle()} | Qlub</title>
            </Head>
            <Toolbar sx={{ ...navBar, justifyContent: 'space-between', paddingLeft: '1rem', paddingRight: '1rem' }}>
                {MenuIconAndTitle}
                <Box sx={AvatarContainer}>
                    {isTableView && <SettingsButton onClick={() => onPushEvent('user_click_on_filter_icon')} />}
                    {withRefresh && (
                        <CustomButton
                            endIcon={<CachedRounded />}
                            size="large"
                            sx={{
                                color: 'primary.main',
                                margin: '0 12px',
                            }}
                            onClick={() => {
                                toggleRefresh(!refresh);
                            }}
                        >
                            {t('Refresh')}
                        </CustomButton>
                    )}
                    {switchLanguageByFlag && <LanguageSelectorWithFlags />}
                    <AccountMenu user={user} restaurant={restaurant} logout={logout} />
                </Box>
            </Toolbar>
        </Nav>
    );
}
