import { IEPOSDevice, IEPOSPrinter } from '@/components/PosPrinteCenter/Printers/Epson/interface';
import { getStatusText } from '@/components/PosPrinteCenter/Printers/Epson/utils';
import { IVendorQsrOrderConfig, QSRPrintSizeEnum } from '@/views/OrdersTableView/types';
import { toCanvas } from 'html-to-image';

declare global {
    const epson: IEpson;
}

interface IEpson {
    ePOSDevice: () => IEPOSDevice;
}

enum RowTypeEnum {
    Normal = 'normal',
    Bold = 'bold',
    Sub = 'sub',
    Total = 'total',
    Header = 'header',
    Divider = 'divider',
    Footer = 'footer',
    Logo = 'logo',
    QR = 'qr',
}

interface IRowData {
    type: RowTypeEnum;
    name: string;
    qty?: string;
    price?: string;
}

export enum PrintEventEnum {
    Connect = 'connect',
    Disconnect = 'disconnect',
    Receive = 'receive',
    Failed = 'failed',
}

export class EpsonPrinterService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new EpsonPrinterService();
        }

        return this.instance;
    }

    private static instance: EpsonPrinterService;

    private ePosDevice: IEPOSDevice | undefined = undefined;

    private printer: IEPOSPrinter | undefined = undefined;

    private printMap: { [key: string]: boolean } = {};

    private config: IVendorQsrOrderConfig = {};

    private events: { [key: string]: { [key: number]: any } } = {};

    private eventIdx = 0;

    private connected = false;

    private connecting = false;

    private lineMaxChars = { [QSRPrintSizeEnum.Normal]: 42, [QSRPrintSizeEnum.Large]: 21 };

    private qtyChars = { [QSRPrintSizeEnum.Normal]: 6, [QSRPrintSizeEnum.Large]: 6 };

    private priceChars = { [QSRPrintSizeEnum.Normal]: 10, [QSRPrintSizeEnum.Large]: 10 };

    private priceLargeChars = { [QSRPrintSizeEnum.Normal]: 2, [QSRPrintSizeEnum.Large]: 2 };

    private priceExtendedChars = { [QSRPrintSizeEnum.Normal]: 2, [QSRPrintSizeEnum.Large]: 2 };

    private indexChars = { [QSRPrintSizeEnum.Normal]: 4, [QSRPrintSizeEnum.Large]: 4 };

    public init(config: IVendorQsrOrderConfig) {
        this.config = config;
        setTimeout(() => {
            this.connect();
        }, 1000);
    }

    public connect() {
        if (this.connecting) {
            return;
        }

        if (this.connected) {
            this.ePosDevice?.disconnect();
        }

        console.log('connecting..., config:', this.config);

        this.connecting = true;
        const ePosDevice = new (epson.ePOSDevice as any)();

        ePosDevice.connect(this.config.printerIP || '', this.config.printerPort || '', this.connectHandler);
        ePosDevice.onreconnecting = () => console.log;
        ePosDevice.onreconnect = () => console.log;
        ePosDevice.ondisconnect = () => {
            this.connected = false;
            this.connecting = false;
            this.callEvent(PrintEventEnum.Disconnect);
        };

        this.ePosDevice = ePosDevice;
    }

    public disconnect() {
        if (!this.connecting && this.connected) {
            return;
        }

        console.log('disconnecting...');

        this.ePosDevice?.disconnect();
        this.connected = false;
    }

    public resetPrintId(id: string) {
        delete this.printMap[id];
    }

    public on(event: PrintEventEnum, fn: any) {
        if (!this.events.hasOwnProperty(event)) {
            this.events[event] = {};
        }
        this.eventIdx++;
        const idx = this.eventIdx;
        this.events[event][idx] = fn;
        return () => {
            delete this.events[event][idx];
        };
    }

    private callEvent(event: PrintEventEnum, payload?: any) {
        const events = this.events[event];
        if (events) {
            Object.values(events).forEach((fn) => {
                fn?.(payload);
            });
        }
    }

    private connectHandler = (data: string) => {
        const { ePosDevice } = this;
        if (!ePosDevice) {
            return;
        }

        const deviceID = this.config.printerDeviceId || 'local_printer';
        const crypto = Boolean(this.config.printerEncrypt);
        const buffer = true;
        const options = { crypto, buffer };

        try {
            if (data === 'OK') {
                console.log('connected to ePOS Device Service Interface.', options);
                ePosDevice.createDevice(deviceID, ePosDevice.DEVICE_TYPE_PRINTER, options, this.createDeviceHandler);
            } else if (data === 'SSL_CONNECT_OK') {
                console.log('connected to ePOS Device Service Interface with SSL.', options);
                ePosDevice.createDevice(deviceID, ePosDevice.DEVICE_TYPE_PRINTER, options, this.createDeviceHandler);
            } else {
                console.log(`failed to connect to ePOS Device Service Interface. [${data}]`);
                this.connected = false;
                this.connecting = false;
                this.callEvent(PrintEventEnum.Failed, { status: data });
            }
        } catch (e) {
            console.log(`failed to connect to ePOS Device Service Interface. [${e}]`);
            this.connected = false;
            this.connecting = false;
            this.callEvent(PrintEventEnum.Failed, e);
        }
    };

    private createDeviceHandler = (data: any, code: string) => {
        console.log(`createDeviceHandler, data: ${data}, code: ${code}`);
        if (data == null) {
            return;
        }

        setTimeout(() => {
            this.callEvent(PrintEventEnum.Connect);
        }, 500);
        console.log(`you can use printer: ${code}`);
        this.printer = data;
        this.connected = true;
        this.connecting = false;

        // Set a response receipt callback function
        data.onreceive = (res: any) => {
            // Show message
            console.log(
                `Print${res.success ? 'Success' : 'Failure'}\nCode:${res.code}\nBattery:${res.battery}\n${getStatusText(
                    res.status,
                )}`,
            );
            this.callEvent(PrintEventEnum.Receive, res);
        };
        // // Set a status change callback funciton
        // printer.onstatuschange = function (status) {
        //     if (document.getElementById('onstatuschange').checked) {
        //         consolelog(getStatusText(status), false);
        //     }
        // };
        // printer.ononline = function () {
        //     if (document.getElementById('ononline').checked) {
        //         consolelog('online', false);
        //     }
        // };
        // printer.onoffline = function () {
        //     if (document.getElementById('ononline').checked) {
        //         consolelog('offline', false);
        //     }
        // };
        // printer.onpoweroff = function () {
        //     if (document.getElementById('ononline').checked) {
        //         consolelog('poweroff', false);
        //     }
        // };
        // printer.oncoverok = function () {
        //     if (document.getElementById('oncoverok').checked) {
        //         consolelog('coverok', false);
        //     }
        // };
        // printer.oncoveropen = function () {
        //     if (document.getElementById('oncoverok').checked) {
        //         consolelog('coveropen', false);
        //     }
        // };
        // printer.onpaperok = function () {
        //     if (document.getElementById('onpaperok').checked) {
        //         consolelog('paperok', false);
        //     }
        // };
        // printer.onpapernearend = function () {
        //     if (document.getElementById('onpaperok').checked) {
        //         consolelog('papernearend', false);
        //     }
        // };
        // printer.onpaperend = function () {
        //     if (document.getElementById('onpaperok').checked) {
        //         consolelog('paperend', false);
        //     }
        // };
        // printer.ondrawerclosed = function () {
        //     if (document.getElementById('ondrawerclosed').checked) {
        //         consolelog('drawerclosed', false);
        //     }
        // };
        // printer.ondraweropen = function () {
        //     if (document.getElementById('ondrawerclosed').checked) {
        //         consolelog('draweropen', false);
        //     }
        // };
        // printer.onbatterystatuschange = function () {
        //     if (document.getElementById('onbatterystatuschange').checked) {
        //         consolelog('onbatterystatuschange', false);
        //     }
        // };
        // printer.onbatteryok = function () {
        //     if (document.getElementById('onbatteryok').checked) {
        //         consolelog('onbatteryok', false);
        //     }
        // };
        // printer.onbatterylow = function () {
        //     if (document.getElementById('onbatteryok').checked) {
        //         consolelog('onbatterylow', false);
        //     }
        // };
    };

    public print(htmlTable: HTMLTableElement): Promise<boolean> {
        return new Promise((resolve) => {
            const id = `${htmlTable.getAttribute('data-id')}${htmlTable.getAttribute('data-type')}`;
            if (!id) {
                resolve(false);
                return;
            }

            if (this.printMap.hasOwnProperty(id)) {
                resolve(false);
                return;
            }

            this.printMap[id] = true;

            const { printer } = this;
            if (!printer) {
                resolve(false);
                return;
            }

            const width = 260;
            const height = (htmlTable.offsetHeight / htmlTable.offsetWidth) * width;

            if (this.config.printerImageBased) {
                toCanvas(htmlTable, {
                    height,
                    width,
                    canvasWidth: width,
                    canvasHeight: height,
                    pixelRatio: 2,
                })
                    .then((el) => {
                        // const img = document.createElement('img');
                        // img.src = el.toDataURL('image/png');
                        // document.body.appendChild(img);
                        // img.style.position = 'absolute';
                        // img.style.top = '0px';
                        // img.style.left = '0px';
                        // img.style.zIndex = '99999';
                        // console.log(width, height, el.width, el.height);
                        const context = el.getContext('2d');
                        if (!context) {
                            throw new Error('cannot create canvas');
                        }

                        printer.addImage(context, 0, 0, el.width, el.height, printer.COLOR_1, printer.MODE_MONO);
                        printer.addCut(printer.CUT_FEED);

                        // Print the receipt
                        printer.send();
                        resolve(true);
                    })
                    .catch(() => {
                        resolve(false);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.resetPrintId(id);
                        }, 100);
                    });
            } else {
                const size = (htmlTable.getAttribute('data-size') || QSRPrintSizeEnum.Normal) as QSRPrintSizeEnum;
                const largeCurrency = htmlTable.getAttribute('data-currency') === 'large';
                const encoding = htmlTable.getAttribute('data-encoding') || '';

                for (let i = 0; i < htmlTable.rows.length; i++) {
                    this.addDetails(printer, htmlTable.rows[i], { size, largeCurrency, encoding });
                }

                printer.addCut(printer.CUT_FEED);

                // Print the receipt
                printer.send();
                resolve(true);

                setTimeout(() => {
                    this.resetPrintId(id);
                }, 100);
            }
        });
    }

    private getRowData(row: HTMLTableRowElement): IRowData {
        const [name, ...rest] = row.cells;
        let qty = '';
        let price = '';
        if (row.getAttribute('data-with-attr')) {
            rest.forEach((item) => {
                if (item.getAttribute('data-type') === 'qty') {
                    qty = item?.innerText;
                } else if (item.getAttribute('data-type') === 'price') {
                    price = item?.innerText;
                }
            });
        } else {
            qty = rest?.[0]?.innerText;
            price = rest?.[1]?.innerText;
        }
        return {
            type: this.getType(row.getAttribute('data-mode')),
            name: (name?.innerText || '').replace(/&#x2F;/g, '/'),
            qty,
            price,
        };
    }

    private getType(type: string | null) {
        switch (type) {
            default:
            case 'normal':
                return RowTypeEnum.Normal;
            case 'bold':
                return RowTypeEnum.Bold;
            case 'sub':
                return RowTypeEnum.Sub;
            case 'total':
                return RowTypeEnum.Total;
            case 'header':
                return RowTypeEnum.Header;
            case 'divider':
                return RowTypeEnum.Divider;
            case 'footer':
                return RowTypeEnum.Footer;
            case 'qr':
                return RowTypeEnum.QR;
        }
    }

    private addDetails(
        printer: IEPOSPrinter,
        row: HTMLTableRowElement,
        {
            size,
            largeCurrency,
            encoding,
        }: {
            size: QSRPrintSizeEnum;
            largeCurrency: boolean;
            encoding: string;
        },
    ) {
        const { type, name, qty, price } = this.getRowData(row);
        const align = row.getAttribute('data-align');
        const whiteSpace = row.getAttribute('data-whitespace');
        const hideIndex = row.getAttribute('data-hideindex') === 'true';
        if (row.hasAttribute('data-size')) {
            size = (row.getAttribute('data-size') || QSRPrintSizeEnum.Normal) as QSRPrintSizeEnum;
        }
        const sizeOffset = size === QSRPrintSizeEnum.Large ? 1 : 0;
        printer.addTextSize(1 + sizeOffset, 1 + sizeOffset);
        switch (type) {
            default:
            case RowTypeEnum.Bold:
            case RowTypeEnum.Normal:
            case RowTypeEnum.Sub:
            case RowTypeEnum.Header:
                if (type === RowTypeEnum.Bold) {
                    printer.addTextSize(2 + sizeOffset, 2 + sizeOffset);
                }
                printer.addTextAlign(align === 'center' ? printer.ALIGN_CENTER : printer.ALIGN_LEFT);
                printer.addTextStyle(
                    false,
                    false,
                    type === RowTypeEnum.Normal || type === RowTypeEnum.Header,
                    type === RowTypeEnum.Sub ? printer.COLOR_1 : printer.COLOR_3,
                );

                // eslint-disable-next-line no-case-declarations
                const priceChars =
                    (whiteSpace === 'expanded'
                        ? this.priceChars[size] + this.priceExtendedChars[size]
                        : this.priceChars[size]) + (largeCurrency ? this.priceLargeChars[size] : 0);
                // eslint-disable-next-line no-case-declarations
                const nameLen = this.getNameChars({
                    hasQty: Boolean(qty),
                    hasPrice: Boolean(price),
                    priceChars,
                    qtyChars: this.qtyChars[size],
                    size,
                });
                this.splitBySize(name, nameLen, size).forEach((part, idx) => {
                    if (encoding) {
                        printer.addTextLang(encoding);
                    }
                    if (type === RowTypeEnum.Header || align === 'center' || !nameLen) {
                        printer.addText(part);
                    } else {
                        if (idx > 0 && !hideIndex) {
                            printer.addText(''.padEnd(this.indexChars[size]));
                        }
                        printer.addText(part.padEnd(idx === 0 ? nameLen : nameLen - this.indexChars[size]));
                    }
                    if (idx === 0) {
                        if (qty) {
                            printer.addText(`   ${qty}`.padEnd(this.qtyChars[size]));
                        }
                        if (price) {
                            printer.addText(`  ${price}`.padEnd(priceChars));
                        }
                    }
                    printer.addText('\n');
                });
                break;
            case RowTypeEnum.Divider:
                printer.addTextAlign(printer.ALIGN_CENTER);
                printer.addTextStyle(false, false, true, printer.COLOR_4);
                printer.addText(
                    `\n${Array.from({ length: size === QSRPrintSizeEnum.Large ? 14 : 28 })
                        .map(() => '-')
                        .join('')}\n`,
                );
                break;
            case RowTypeEnum.Total:
                printer.addTextStyle(false, false, false, printer.COLOR_1);
                printer.addText(`${name}\t\t\t\t${price}\n`);
                break;
            case RowTypeEnum.Footer:
                printer.addTextStyle(false, false, false, printer.COLOR_1);
                printer.addText(`${name}\n`);
                break;
            case RowTypeEnum.QR:
                printer.addTextAlign(printer.ALIGN_CENTER);
                printer.addSymbol(
                    row.getAttribute('data-qr') || '',
                    printer.SYMBOL_QRCODE_MODEL_2,
                    printer.LEVEL_Q,
                    6,
                    6,
                );
                break;
        }
    }

    private getNameChars({
        hasQty,
        hasPrice,
        qtyChars,
        priceChars,
        size,
    }: {
        hasQty?: boolean;
        hasPrice?: boolean;
        qtyChars: number;
        priceChars: number;
        size: QSRPrintSizeEnum;
    }) {
        let len = this.lineMaxChars[size];
        if (hasQty) {
            len -= qtyChars;
        }
        if (hasPrice) {
            len -= priceChars;
        }
        return len === this.lineMaxChars[size] ? 0 : len;
    }

    private splitBySize(str: string, len: number, size: QSRPrintSizeEnum): string[] {
        if (!len) {
            return [str];
        }
        const parts: string[] = [];
        let idx = 0;
        for (let i = 0; i < str.length; i += idx === 0 ? len : len - this.indexChars[size]) {
            parts.push(str.slice(i, i + (idx === 0 ? len : len - this.indexChars[size])));
            idx++;
        }
        return parts;
    }
}
