import { IQsrOrderNotification } from '@/views/QsrOrders/types';
import { MenuCategoryResponse, MenuProductResponse } from '@/services/menuService/types';
import {
    QSRPrintLayoutEnum,
    QSRPrintSizeEnum,
    IRestaurant,
    IOrderConfig,
    OrderPriceRoundingModeEnum,
} from '@/views/OrdersTableView/types';
import { EpsonPrinterService } from '@/components/PosPrinteCenter/Printers/Epson';
import { useTranslation } from '@/hooks/translations';
import { OrderRows } from '@/components/PosPrinteCenter/Layouts/Common';

import styles from '../index.module.scss';

interface IProps {
    vendor: IRestaurant | null;
    order: IQsrOrderNotification;
    productMap: { [key: string]: MenuProductResponse };
    categoryMap: { [key: string]: MenuCategoryResponse };
    currencyCode: string;
    currencySymbol: string;
    roundingMode?: OrderPriceRoundingModeEnum;
    itemSize?: QSRPrintSizeEnum;
    encoding?: string;
    lang: string;
    config?: IOrderConfig;
    onDone: (order: IQsrOrderNotification, layout?: QSRPrintLayoutEnum) => void;
}

export const LayoutKitchen = ({
    order,
    productMap,
    categoryMap,
    currencyCode,
    currencySymbol,
    roundingMode,
    itemSize,
    encoding,
    lang,
    config,
    onDone,
}: IProps) => {
    const { t } = useTranslation('common');
    const refHandler = (ref: any | undefined) => {
        if (!ref) {
            return;
        }

        EpsonPrinterService.getInstance()
            .print(ref)
            .then(() => {
                onDone?.(order, QSRPrintLayoutEnum.Kitchen);
            });
    };

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0, zIndex: 9999, overflow: 'hidden' }}>
            <table
                ref={refHandler}
                className={styles.layout}
                data-id={order.id}
                data-type="kitchen"
                style={{ backgroundColor: '#fff' }}
                data-size={itemSize}
                data-currency={currencySymbol.length > 1 ? 'large' : 'normal'}
                data-encoding={encoding}
            >
                <tr data-mode="header" data-align="center" data-size={itemSize}>
                    <td colSpan={2} />
                </tr>
                {order.ticketId && (
                    <tr data-mode="header" data-align="center" data-size={itemSize}>
                        <td colSpan={2}>{t('Ticket: {{id}}', { id: order.ticketId })}</td>
                    </tr>
                )}
                {order.urlData.tableId && (
                    <tr data-mode="header">
                        <td colSpan={2}>
                            {order.urlData.tableId.indexOf('-') === 0
                                ? t('Pager: {{id}}', { id: order.urlData.name || order.urlData.tableId.slice(1) })
                                : t('Table: {{id}}', { id: order.urlData.name || order.urlData.tableId })}
                        </td>
                    </tr>
                )}
                {order.userInfo?.name && (
                    <tr data-mode="normal">
                        <td colSpan={2}>{t('Customer Name: {{name}}', { name: order.userInfo?.name || '' })}</td>
                    </tr>
                )}
                {order.date && (
                    <tr data-mode="header">
                        <td colSpan={2}>
                            {config?.printerImageBased
                                ? `${t('Date:')} ${order.date}`
                                : t('Date: {{date}}', { date: order.date })}
                        </td>
                    </tr>
                )}
                <tr data-mode="divider">
                    <td colSpan={2} />
                </tr>
                <OrderRows
                    items={order.orderData.items}
                    productMap={productMap}
                    categoryMap={categoryMap}
                    currencyCode={currencyCode}
                    currencySymbol={currencySymbol}
                    roundingMode={roundingMode}
                    indent={0}
                    hidePrice
                    hideIndex
                    xQty
                    size={itemSize}
                    lang={lang}
                    config={config}
                />
                <tr data-mode="divider">
                    <td colSpan={2} />
                </tr>
                {order.customerComment && (
                    <tr data-mode="normal">
                        <td colSpan={2}>{order.customerComment}</td>
                    </tr>
                )}
            </table>
        </div>
    );
};
