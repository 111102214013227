import { Table, TableBody, TableContainer, TableFooter, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useLanguageQuery } from 'next-export-i18n';

import { useTranslation } from '@/hooks/translations';
import { IPaymentTransaction } from '@/views/Orders/types';
import { ILayout, IPaymentDetails } from './types';
import { parsePaymentDetails } from './transformer';
import { InvoiceBill, InvoiceOrder } from './invoice-bill-order-item';

import styles from './index.module.scss';
import { getDateAndTime } from './utils';

function InvoiceTable({
    layout,
    cc,
    billData,
    abn,
    transaction,
    currencyCode,
    currencySymbol,
}: {
    layout: { itemLayout: ILayout[]; billLayout: ILayout[] };
    cc: string;
    billData: any;
    abn?: string;
    transaction?: IPaymentTransaction;
    currencyCode: string;
    currencySymbol: string;
}) {
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const details: IPaymentDetails = useMemo(
        () =>
            parsePaymentDetails(
                { ...billData, abn },
                layout.billLayout?.length > 0 || layout.itemLayout?.length > 0
                    ? { cc, bill: layout.billLayout, item: layout.itemLayout }
                    : undefined,
            ),
        [layout],
    );

    return (
        <div className={styles.invoice}>
            <div className={styles.header}>
                <Typography className={styles.title}>
                    {t('Table {{table}}', {
                        table: details.tableName || details.tableID || '',
                    })}
                </Typography>
                <Typography className={styles.dateTitle}>{getDateAndTime(transaction?.order?.date, lang)}</Typography>
            </div>
            {details && (
                <TableContainer>
                    <Table
                        className={classNames(styles.invoiceTable, {
                            [styles.compactTable]: false,
                        })}
                        aria-label="sticky table"
                    >
                        <TableBody>
                            <InvoiceOrder
                                details={details}
                                lang={lang}
                                currencyCode={currencyCode}
                                currencySymbol={currencySymbol}
                            />
                        </TableBody>
                        <TableFooter>
                            <InvoiceBill
                                details={details}
                                lang={lang}
                                currencyCode={currencyCode}
                                currencySymbol={currencySymbol}
                            />
                        </TableFooter>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

export default InvoiceTable;
