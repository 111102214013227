import { useMemo, useState } from 'react';
import { useLanguageQuery } from 'next-export-i18n';

import SearchOutlined from '@mui/icons-material/SearchOutlined';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import { type ITimeRangeForm, TimeRangeTypes } from '@/components/TimeRange/Form/types';

export const useFilters = () => {
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';

    const [searchOrder, setSearchOrder] = useState<string | null>('');
    const [backendSearchValue, setBackendSearchValue] = useState<string | null>('');
    const [selectedStatus, setSelectedStatus] = useState<string>('All');
    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: TimeRangeTypes.ALL,
        from: null,
        to: null,
    });

    const filterMenu = useMemo(
        () => [
            {
                id: 'all',
                label: t('All'),
                value: 'All',
            },
            {
                id: 'paid',
                label: t('Paid'),
                value: 'paid',
            },
            {
                id: 'partially',
                label: t('Partially Paid'),
                value: 'partial',
            },
            {
                id: 'refunded',
                label: t('Refunded'),
                value: 'refund',
            },
            {
                id: 'rejected',
                label: t('Rejected'),
                value: 'rejected',
            },
        ],
        [lang],
    );

    const [timeoutId, setTimeoutId] = useState<any>();

    const handleDebouncedGtmEvent = (eventName: string) => {
        clearTimeout(timeoutId);
        setTimeoutId(
            setTimeout(() => {
                onPushEvent(eventName);
            }, 1000),
        );
    };

    const renderFilters = () => {
        return (
            <Stack
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2}
                sx={{
                    marginBottom: '1rem',
                }}
                direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                width={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}
            >
                <TextField
                    size="small"
                    sx={{ marginTop: '0.5rem', width: '100%' }}
                    label={t('Order ID')}
                    InputProps={{
                        startAdornment: <SearchOutlined sx={{ color: '#A9A9A9', marginRight: '0.3rem' }} />,
                        endAdornment: (
                            <Button
                                onClick={() => {
                                    setSearchOrder(backendSearchValue);
                                    handleDebouncedGtmEvent('user_use_search_order_id_on_orders');
                                }}
                                disabled={!backendSearchValue}
                            >
                                {t('Search')}
                            </Button>
                        ),
                    }}
                    onChange={(ev) => {
                        if (ev.target.value === '') {
                            setSearchOrder(null);
                        }
                        setBackendSearchValue(ev.target.value);
                    }}
                />

                <FormControl
                    size="small"
                    sx={{
                        marginTop: '0.5rem',
                        width: '100%',
                    }}
                >
                    <InputLabel>{t('Status')}</InputLabel>
                    <Select
                        label={t('Status')}
                        value={selectedStatus}
                        onChange={(ev) => {
                            setSelectedStatus(ev.target.value);
                            onPushEvent('user_filter_status');
                        }}
                    >
                        {filterMenu.map((item) => {
                            return (
                                <MenuItem value={item.value} id={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Stack>
        );
    };

    return {
        renderFilters,
        searchOrder,
        selectedStatus,
        setSearchOrder,
        timeRange,
        setTimeRange,
    };
};
