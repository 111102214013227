import { useRef } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { useTranslation } from '@/hooks/translations';
import CustomDateTimePicker from '@/components/CustomDateTimePicker';

import { useData } from './hooks/useData';
import { useActions } from './hooks/useActions';
import { TimeRangeTypes, type ITimeRangeForm, type TimeRangeFormProps } from './types';

const TimeRangeForm = ({ minDate, options, timeSelectEnabled }: TimeRangeFormProps) => {
    const { t } = useTranslation('common');
    const toRef = useRef<HTMLBodyElement>();

    const { values } = useFormikContext<ITimeRangeForm>();
    const { from, type } = values;

    const { timeRangeOptions } = useData(options);
    const { customChangeHandler, timeRangeChangeHandler } = useActions({
        toClick: () => toRef.current?.click(),
    });

    return (
        <Grid item xs={12}>
            <Field name="type">
                {({ field }: FieldProps) => (
                    <RadioGroup {...field}>
                        <Grid container spacing={1}>
                            {timeRangeOptions?.map((option, index) => (
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                value={option.name}
                                                control={<Radio />}
                                                onClick={() => {
                                                    timeRangeChangeHandler(option.name);
                                                }}
                                                label={t(option.title)}
                                            />
                                        </Grid>
                                        <Grid item xs={6} display="flex" alignItems="center" justifyContent="flex-end">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {option.dateString}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12} sm={2} md={12}>
                                <FormControlLabel
                                    value={TimeRangeTypes.CUSTOM}
                                    control={<Radio />}
                                    label={t('Custom')}
                                    onClick={() => {
                                        timeRangeChangeHandler(TimeRangeTypes.CUSTOM);
                                    }}
                                />
                            </Grid>
                            {type === TimeRangeTypes.CUSTOM && (
                                <Grid item xs={12} sm={10} md={12}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Field name="from">
                                                    {({ meta: { value } }: FieldProps) => (
                                                        <CustomDateTimePicker
                                                            value={value ? new Date(value) : new Date()}
                                                            timeSelectEnabled={timeSelectEnabled}
                                                            onChange={customChangeHandler('from')}
                                                            disabled={type !== 'custom'}
                                                            textFieldLabel={t('From')}
                                                            modalTitle={t('Select the first date')}
                                                            minDate={minDate}
                                                            fromTo="from"
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field name="to">
                                                    {({ meta: { value } }: FieldProps) => (
                                                        <CustomDateTimePicker
                                                            value={value ? new Date(value) : new Date()}
                                                            timeSelectEnabled={timeSelectEnabled}
                                                            onChange={customChangeHandler('to')}
                                                            disabled={type !== 'custom'}
                                                            textFieldLabel={t('To')}
                                                            modalTitle={t('Select the last date')}
                                                            minDate={from ? new Date(from) : minDate}
                                                            fromTo="to"
                                                            toRef={toRef}
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </LocalizationProvider>
                                </Grid>
                            )}
                        </Grid>
                    </RadioGroup>
                )}
            </Field>
        </Grid>
    );
};

export default TimeRangeForm;
