import React from 'react';
import styles from './index.module.scss';

interface IProps {
    active?: boolean;
}

export function Indicator({ active }: IProps) {
    return (
        <div className={styles.wrapper}>
            <div className={`${styles.itemIndicator} ${active ? styles.active : ''}`} />
        </div>
    );
}
