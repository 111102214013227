import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { Bill, Discount, Dollar, ItemBill, ItemPercent, Percent, Rial, Yen } from './svg';
import { useBillItemTranslation, useOrderItemTranslation } from './translations_hook';
import { ColorEnum, ConditionEnum, IconEnum, StyleEnum, IKeyValue, IKeyValueOrder, IPaymentDetails } from './types';
import { applyThousandSeparator, getIdFromOrder } from './utils';

import styles from './index.module.scss';

const currencyCodeDefault = 'USD';
const currencySymbolDefault = '$';

const checkVisibilityCondition = (val: IKeyValue, qlubDiscount?: string) => {
    const qd = Number(qlubDiscount || '0');
    const v = Number(val.value || '0');
    switch (val?.layout?.vcond || ConditionEnum.Default) {
        default:
        case ConditionEnum.Default:
            return true;
        case ConditionEnum.GT:
            return v > 0;
        case ConditionEnum.GTE:
            return v >= 0;
        case ConditionEnum.LT:
            return v < 0;
        case ConditionEnum.LTE:
            return v < 0;
        case ConditionEnum.E:
            return v === 0;
        case ConditionEnum.NE:
            return v !== 0;
        case ConditionEnum.GTD:
            return v > 0 && qd > 0;
    }
};

interface IInvoiceItemProps {
    val: IKeyValue | IKeyValueOrder;
    currencyCode: string;
    currencySymbol: string;
    currencyPrecision: number;
    lang: string | null;
    type?: 'bill' | 'order' | 'topping';
    isNew?: boolean;
    onClick?: (item: IKeyValueOrder) => void;
}

export function InvoiceItem({
    val,
    currencyCode,
    currencySymbol,
    currencyPrecision,
    lang,
    type,
    isNew,
    onClick,
}: IInvoiceItemProps) {
    const billMode = !type || type === 'bill';
    const { getTrans } = billMode ? useBillItemTranslation(lang, currencyCode) : useOrderItemTranslation(lang);
    const getIcon = () => {
        switch (val.layout?.icon) {
            case IconEnum.Dollar:
                return <Dollar />;
            case IconEnum.Yen:
                return <Yen />;
            case IconEnum.Rial:
                return <Rial />;
            case IconEnum.Percent:
                return <Percent />;
            case IconEnum.Bill:
                return <Bill />;
            case IconEnum.Discount:
                return <Discount />;
            case IconEnum.ItemPercent:
                return <ItemPercent />;
            case IconEnum.ItemBill:
                return <ItemBill />;
            default:
                if (type === 'topping' || type === 'order') {
                    let qty = (val as IKeyValueOrder).qty || '0';
                    qty = Number(qty) === Math.floor(Number(qty)) ? Number(qty).toFixed(0) : qty;
                    if (qty !== '0') {
                        return (
                            <div
                                className={classNames(styles.itemQty, {
                                    [styles.itemQtyTopping]: type === 'topping',
                                })}
                            >
                                {qty}
                            </div>
                        );
                    }
                }
                return null;
        }
    };
    const getStyleClass = () => {
        switch (val.layout?.style) {
            case StyleEnum.TitleMedium:
                return [styles.styleTitleMedium];
            case StyleEnum.TitleSmall:
                return [styles.styleTitleSmall];
            case StyleEnum.BodyMedium:
                return [styles.styleBodyMedium];
            case StyleEnum.BodySmall:
                return [styles.styleBodySmall];
            case StyleEnum.BodyMediumStrike:
                return [styles.styleBodyMedium, styles.styleStrike];
            case StyleEnum.BodySmallStrike:
                return [styles.styleBodySmall, styles.styleStrike];
            default:
                return [];
        }
    };
    const getColorClass = () => {
        switch (val.layout?.color) {
            case ColorEnum.Primary:
                return [styles.colorPrimary];
            case ColorEnum.Secondary:
                return [styles.colorSecondary];
            case ColorEnum.HighEmphasis:
                return [styles.colorHighEmphasis];
            case ColorEnum.MediumEmphasis:
                return [styles.colorMediumEmphasis];
            case ColorEnum.LowEmphasis:
                return [styles.colorLowEmphasis];
            case ColorEnum.Disabled:
                return [styles.colorDisabled];
            case ColorEnum.Background:
                return [styles.colorBackground];
            case ColorEnum.Green:
                return [styles.colorGreen];
            default:
                return [];
        }
    };
    const getTitle = () => {
        if (billMode) {
            return getTrans(val.key, val.title, val.layout?.locales);
        }
        return (val as IKeyValueOrder).title || getTrans(val.key, val.title, val.layout?.locales);
    };

    const getValue = (value?: string, dontApplyThousandSeperator?: boolean) => {
        if (!value) {
            return null;
        }

        if (!dontApplyThousandSeperator && Number(value)) {
            value = applyThousandSeparator(value, currencyPrecision || 0);
        }

        if (val.layout?.format) {
            if (val.layout.newFormat) {
                return val.layout.format
                    .replace('{0}', value)
                    .replace('{ccf}', currencyCode)
                    .replace('{csf}', currencySymbol);
            }
            return val.layout.format
                .replace('{0}', value)
                .replace('{cc}', currencyCode)
                .replace('{cs}', currencySymbol);
        }
        return value;
    };
    const clickHandler = () => {
        onClick?.(val);
    };
    return (
        <TableRow
            className={classNames(
                styles.invoiceItem,
                { [styles.topping]: type === 'topping' },
                { [styles.newRow]: isNew },
                ...getStyleClass(),
                ...getColorClass(),
            )}
            onClick={clickHandler}
        >
            <TableCell>
                <div className={styles.iconContainer}>{getIcon()}</div>
                {getTitle()}
            </TableCell>
            {!billMode && !val.layout?.hideAltValue && (
                <TableCell align="right" className={classNames(styles.itemValue, styles.noWrap, styles.altValue)}>
                    {getValue((val as IKeyValueOrder).altValue, val.key === 'abn')}
                </TableCell>
            )}
            <TableCell
                align="right"
                className={classNames(styles.itemValue, styles.noWrap, { [styles.value]: !billMode })}
                colSpan={billMode ? 2 : 1}
            >
                {getValue(val.value, val.key === 'abn')}
            </TableCell>
        </TableRow>
    );
}

interface IInvoiceBillProps {
    details: IPaymentDetails;
    lang: string | null;
    currencyCode: string;
    currencySymbol: string;
}

export const InvoiceBill = ({ details, lang, currencyCode, currencySymbol }: IInvoiceBillProps) => (
    <>
        {details._billItems.map((item, i) => {
            if (!checkVisibilityCondition(item, details.bill.qlubDiscount)) {
                return null;
            }
            return (
                <InvoiceItem
                    key={`${item.key}_${item.value}_${i}`}
                    val={item}
                    currencyCode={currencyCode || currencyCodeDefault || ''}
                    currencySymbol={currencySymbol || currencySymbolDefault || ''}
                    currencyPrecision={details.currencyPrecision || 0}
                    lang={lang}
                />
            );
        })}
    </>
);

interface IInvoiceOrderProps {
    details: IPaymentDetails;
    lang: string | null;
    currencyCode: string;
    currencySymbol: string;
}

export const InvoiceOrder = ({ details, lang, currencyCode, currencySymbol }: IInvoiceOrderProps) => (
    <>
        {details._orderItems.map((item, i) => {
            const orderId = getIdFromOrder(item.item, undefined, true);
            return (
                <>
                    <InvoiceItem
                        key={`${orderId}_${i}`}
                        val={item.item}
                        currencyCode={currencyCode || currencyCodeDefault || ''}
                        currencySymbol={currencySymbol || currencySymbolDefault || ''}
                        currencyPrecision={details.currencyPrecision || 0}
                        lang={lang}
                        type="order"
                    />
                    {item.items.map((innerItem, j) => {
                        if (!checkVisibilityCondition(innerItem)) {
                            return null;
                        }
                        return (
                            <InvoiceItem
                                key={`${orderId}_${i}_${j}`}
                                val={innerItem}
                                currencyCode={currencyCode || currencyCodeDefault || ''}
                                currencySymbol={currencySymbol || currencySymbolDefault || ''}
                                currencyPrecision={details.currencyPrecision || 0}
                                lang={lang}
                                type="topping"
                            />
                        );
                    })}
                </>
            );
        })}
    </>
);
