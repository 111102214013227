import axios, { baseUrl } from '@/config/axios';
import { EnableMfaResponse, IGoogleAuthLogin, IOAuthResponse, LoginResponse } from '@/interfaces/auth/reponse';
import {
    ChangePasswordForm,
    LoginFormData,
    MfaFormData,
    VerifyTokenFormData,
    ResetPasswordVerify,
} from '@/interfaces/auth/form';
import { transformError } from '.';

const endpoints = {
    AUTH_GOOGLE: '/auth/google',
    AUTH_GOOGLE_CALLBACK: '/auth/google/callback',
    ENABLE_MFA: '/auth/enable-mfa',
    LOGIN: '/auth/login',
    RESET_PASSWORD: '/auth/reset-password',
    RESET_PASSWORD_VERIFY: '/auth/verify-password',
    SEND_VERIFY: '/admin/brand/:brand_id/send-verify',
    VERIFY_TOKEN: '/auth/verify-token',
};

class AuthService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new AuthService();
        }

        return this.instance;
    }

    private static instance: AuthService;

    private googleCallbackLoading = false;

    public login(data: LoginFormData) {
        return axios
            .post<LoginResponse>(endpoints.LOGIN, data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public loginV2(data: LoginFormData) {
        return axios
            .post<LoginResponse>(endpoints.LOGIN, data, {
                baseURL: baseUrl.replace('/v1', '/v2'),
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public enableMfa({ token, accessToken, type }: MfaFormData) {
        return axios
            .post<EnableMfaResponse>(
                endpoints.ENABLE_MFA,
                { accessToken, type },
                { headers: token ? { Authorization: `Bearer ${token}`, user_type: type, 'x-user-type': type } : {} },
            )
            .then((res) => res.data)
            .then((res) => {
                const { qrCode, challengeName } = res.data;
                return { qrCode, challengeName };
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    public async verifyToken(data: VerifyTokenFormData) {
        try {
            const { data: tokenData } = await axios.post<LoginResponse>(endpoints.VERIFY_TOKEN, { ...data });

            if (data.challengeName === 'MFA_SETUP') {
                return true;
            }

            return tokenData;
        } catch (err) {
            throw transformError(err);
        }
    }

    public disableMfa({ accessToken }: MfaFormData) {
        return axios
            .post<EnableMfaResponse>(`/auth/disable-mfa`, { accessToken })
            .then((res) => res.data)
            .then((res) => res)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public changePassword(data: ChangePasswordForm) {
        return axios
            .post(`/auth/change-password`, data)
            .then((res) => res.data)
            .then((res) => res)
            .catch((err) => {
                // throw err;
                throw transformError(err);
            });
    }

    public resetPassword(email: string) {
        return axios
            .post(
                endpoints.RESET_PASSWORD,
                {
                    email,
                },
                {
                    headers: {
                        user_type: 'vendor',
                        'x-user-type': 'vendor',
                    },
                },
            )
            .then((res) => res.data)
            .then((res) => res)
            .catch((err) => {
                // throw err;
                throw transformError(err);
            });
    }

    public resetPasswordVerify(params: ResetPasswordVerify) {
        return axios
            .get(endpoints.RESET_PASSWORD_VERIFY, {
                params,
            })
            .then((res) => res.data)
            .then((res) => res)
            .catch((err) => {
                // throw err;
                throw transformError(err);
            });
    }

    public googleAuth(token: string) {
        return axios
            .post<IOAuthResponse>(endpoints.AUTH_GOOGLE, { recaptcha: token, vendor: true })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public googleAuthLogin(data: IGoogleAuthLogin) {
        if (this.googleCallbackLoading) {
            return;
        }

        this.googleCallbackLoading = true;

        // eslint-disable-next-line consistent-return
        return axios
            .post<LoginResponse>(endpoints.AUTH_GOOGLE_CALLBACK, { ...data, vendor: true })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            })
            .finally(() => {
                this.googleCallbackLoading = false;
            });
    }

    public verify(brandId: string) {
        return axios
            .post<Record<any, any>>(endpoints.SEND_VERIFY.replace(':brand_id', brandId))
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }
}

export default AuthService;
