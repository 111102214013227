import { IQsrOrder } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';

import { useRestaurantContext } from '@/contexts/restaurant';
import { getCurrencyWithAmount } from '@/common/utility';
import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
    currencySymbol: string;
    currencyCode: string;
}

export default function QsrOrderInfo({ order, currencyCode, currencySymbol }: IProps) {
    const { t } = useTranslation('common');
    const { restaurant } = useRestaurantContext();
    const priceRoundingMode = restaurant?.order_config?.priceRoundingMode;

    return (
        <div className={styles.main}>
            {order.subTotalStr && (
                <div className={styles.row}>
                    <div className={styles.label}>{t('Subtotal')}</div>
                    <div className={styles.value}>
                        {getCurrencyWithAmount(order.subTotal, currencySymbol, currencyCode, true, priceRoundingMode)}
                    </div>
                </div>
            )}
            {order.totalStr && (
                <div className={styles.row}>
                    <div className={styles.label}>{t('Total')}</div>
                    <div className={styles.value}>
                        {getCurrencyWithAmount(order.total, currencySymbol, currencyCode, true, priceRoundingMode)}
                    </div>
                </div>
            )}
            {order.customerComment && (
                <div className={classNames(styles.row, styles.comment)}>
                    <div className={classNames(styles.label, styles.fullWidth)}>{t('Customer Comment')}</div>
                    <div className={styles.value}>{order.customerComment}</div>
                </div>
            )}
        </div>
    );
}
