import { v4 as uuidv4 } from 'uuid';
import { getDefaultBillLayout, getDefaultOrderLayout } from './defaults';
import {
    IGetPaymentDetailConfig,
    IKeyValue,
    ILayout,
    IOrderItemView,
    IPaymentDetails,
    IPaymentDetailsResponse,
    IPaymentParty,
    IPaymentPartyUI,
    SplitModeType,
} from './types';

export const getNumberOfPaidItems = (parties: IPaymentParty[], index: number): number => {
    return parties?.reduce<number>((a, party) => {
        if (party.paymentDone) {
            const tmp = party.billSplit?.items?.find((item) => item.index === index);
            if (tmp) {
                a += Number(tmp?.qty);
            }
        }
        return a;
    }, 0);
};

const decideSplitMode = (
    parties: IPaymentPartyUI[],
    sessionId: string,
    orderItems?: IOrderItemView[],
): SplitModeType => {
    if (parties.length === 0) {
        return SplitModeType.empty;
    }
    let paidSplitMode: SplitModeType = SplitModeType.empty;
    let firstSplitMode: SplitModeType = SplitModeType.empty;
    parties.forEach((party) => {
        if (!paidSplitMode && party.billSplit?.splitMode && party.paidAt) {
            paidSplitMode = party.billSplit?.splitMode;
        }
        if (!firstSplitMode && party.billSplit?.splitMode && party.id === sessionId) {
            firstSplitMode = party.billSplit?.splitMode;
        }
    });
    if (paidSplitMode && paidSplitMode !== SplitModeType.custom && orderItems?.some((o) => o.updatedAt)) {
        return SplitModeType.custom;
    }
    return paidSplitMode || firstSplitMode;
};

const getYourSplitSettings = (parties: IPaymentPartyUI[], sessionId: string): IPaymentPartyUI => {
    const party = parties.find((o) => o.id === sessionId);
    if (party) {
        return party;
    }
    return {
        id: sessionId,
        name: '',
        amount: '0',
        amountNumber: 0,
        billSplit: {
            splitMode: SplitModeType.unknown,
            items: [],
            share: 0,
            totalShares: 0,
            meta: null,
        },
    };
};

const getOtherSplitSettings = (parties: IPaymentPartyUI[], sessionId: string): IPaymentPartyUI => {
    return parties.reduce<IPaymentPartyUI>(
        (prevValue, party) => {
            if (!party.paymentDone || party.id === sessionId) {
                return prevValue;
            }
            prevValue.amount += party.amount;
            prevValue.billSplit.totalShares = party.billSplit.totalShares;
            if (party.billSplit.share) {
                prevValue.billSplit.share += party.billSplit.share;
            }
            if (!prevValue.billSplit.items) {
                prevValue.billSplit.items = [];
            }

            party.billSplit.items?.forEach((item) => {
                const idx = prevValue.billSplit.items?.findIndex((o) => o.index === item.index) || -1;
                if (idx > -1 && prevValue.billSplit.items && prevValue.billSplit.items[idx]) {
                    prevValue.billSplit.items[idx].qty += item.qty;
                } else {
                    prevValue.billSplit.items?.push(item);
                }
            });
            prevValue.paymentDone = party.paymentDone;

            return prevValue;
        },
        {
            id: 'other',
            amount: '0',
            amountNumber: 0,
            paymentDone: false,
            paidAt: 0,
            name: '',
            nonQlub: false,
            billSplit: {
                splitMode: SplitModeType.unknown,
                items: [],
                share: 0,
                totalShares: 0,
                meta: null,
            },
        },
    );
};

const checkYourShare = (details: IPaymentDetails, yourSplitSetting: IPaymentPartyUI) => {
    if (
        details.billSplit?.mode === SplitModeType.byShare &&
        !yourSplitSetting.paymentDone &&
        yourSplitSetting.billSplit.totalShares !== details?.billSplit.totalSharesNumber &&
        yourSplitSetting.billSplit.share &&
        details.billSplit?.shareAmount
    ) {
        const newShare = details.billSplit.shareAmountNumber * yourSplitSetting.billSplit.share;
        details.billNumber.yourShare = newShare;
        details.bill.yourShare = String(newShare);
    }
    return details;
};

export const getRemainingSplitItems = (details: IPaymentDetails) => {
    const allItems = details._orderItems.reduce<{ [key: number]: number }>((a, b, idx) => {
        a[idx] = Number(b.item.qty);
        return a;
    }, {});
    details.otherSplitSettings.billSplit.items?.forEach((item) => {
        const a = allItems[item.index];
        if (a !== undefined) {
            allItems[item.index] -= item.qty;
        }
    });
    return allItems;
};

const detectSplitConflict = (details: IPaymentDetails) => {
    if (!details.billSplit || details.billSplit?.mode !== SplitModeType.byItem) {
        return details;
    }
    const allItems = getRemainingSplitItems(details);
    details.billSplit.conflict = details.yourSplitSettings.billSplit.items?.some((item) => {
        const a = allItems[item.index];
        return a !== undefined && a < item.qty;
    });
    return details;
};

export const hasCookieAccess = () => {
    // @ts-ignore
    if (!process.browser) {
        return false;
    }
    try {
        return window.localStorage;
    } catch {
        return false;
    }
};

function getSessionKey() {
    return 'qlub.session';
}

const session: {
    id: string | undefined;
} = {
    id: undefined,
};

export function getSession(defaultSessionId?: string) {
    if (defaultSessionId) {
        session.id = defaultSessionId;
        return defaultSessionId;
    }
    if (session.id) {
        return session.id || '1';
    }
    const s = hasCookieAccess() ? sessionStorage.getItem(getSessionKey()) : null;
    if (s) {
        session.id = s;
    } else {
        session.id = uuidv4();
        if (hasCookieAccess()) {
            sessionStorage.setItem(getSessionKey(), session.id || '');
        }
    }
    return session.id || '1';
}

const transformKeys = (details: IPaymentDetailsResponse): IPaymentDetails => {
    const sessionId = getSession();
    const parties: IPaymentPartyUI[] =
        details.parties?.map((party) => ({
            ...party,
            amountNumber: Number(party.amount),
            billSplit: {
                ...party?.billSplit,
                share: Number(party.billSplit?.share),
                totalShares: Number(party.billSplit?.totalShares),
                items: party.billSplit?.items?.map((item) => ({
                    ...item,
                    qty: Number(item.qty),
                })),
            },
        })) || [];
    const yourSplitSettings = getYourSplitSettings(parties, sessionId);
    const transformedDetails = {
        ...details,
        bill: {
            ...details.bill,
            subTotal: details.bill?.billAmount || '0',
            total: details.bill?.payableAmount || '0',
        },
        billNumber: {
            billAmount: Number(details.bill?.billAmount || '0'),
            paid: Number(details.bill?.paid || '0'),
            payableAmount: Number(details.bill?.payableAmount || '0'),
            tax: Number(details.bill?.tax || '0'),
            vat: Number(details.bill?.vat || '0'),
            qlubDiscount: Number(details.bill?.qlubDiscount || '0'),
            remaining: Number(details.bill?.remaining || '0'),
            yourShare: Number(details.bill?.yourShare || '0'),
            subTotal: Number(details.bill?.billAmount || '0'),
            total: Number(details.bill?.payableAmount || '0'),
        },
        billSplit: details.billSplit
            ? {
                  ...details.billSplit,
                  totalSharesNumber: Number(details.billSplit.totalShares),
                  shareAmountNumber: Number(details.billSplit.shareAmount),
                  mode: details.billSplit.mode || decideSplitMode(parties, sessionId, details.orderItems),
              }
            : undefined,
        parties,
        yourSplitSettings,
        otherSplitSettings: getOtherSplitSettings(parties, sessionId),
        _billItems: [
            {
                key: 'def_qlubDiscount',
                value: details.bill.qlubDiscount,
            },
            {
                key: 'def_paid',
                value: details.bill.paid,
            },
            {
                key: 'def_remaining',
                value: details.bill.remaining,
            },
            {
                key: 'def_subTotal',
                value: details.bill.billAmount,
            },
            {
                key: 'def_tax',
                value: details.bill.tax,
            },
            {
                key: 'def_vat',
                value: details.bill.vat,
            },
            {
                key: 'def_total',
                value: details.bill.payableAmount,
            },
            {
                key: 'def_yourShare',
                value: details.bill.yourShare,
            },
            {
                key: 'def_billAmount',
                value: details.bill.billAmount,
            },
            {
                key: 'def_payableAmount',
                value: details.bill.payableAmount,
            },
            {
                key: 'abn',
                value: details.abn,
            },
            ...(details.bill.additives?.map((item) => ({
                key: item.key,
                value: item.amount || item.value,
                title: item.title,
            })) || []),
        ],
        _orderItems:
            details.orderItems?.map((item, index) => ({
                item: {
                    key: 'def_order',
                    title: item.title,
                    value: Number(item.finalPrice || item.subTotal || '0')
                        ? item.finalPrice || item.subTotal
                        : item.unitPrice,
                    altValue: Number(item.finalPrice || item.subTotal || '0') ? item.unitPrice : undefined,
                    secondAltValue: item.finalUnitPrice,
                    qty: item.qty,
                    updatedAt: item.updatedAt,
                    selectedItemsQty:
                        (!yourSplitSettings.paymentDone &&
                            yourSplitSettings.billSplit.items?.find((i) => i.index === index)?.qty) ||
                        0,
                    paidItemsQty: getNumberOfPaidItems(details.parties || [], index),
                },
                items: [
                    ...(item.toppings?.map((innerItem) => ({
                        key: 'def_order_topping',
                        title: innerItem.title,
                        value: innerItem.finalPrice || innerItem.subTotal,
                        altValue: innerItem.unitPrice,
                        qty: innerItem.qty,
                        updatedAt: innerItem.updatedAt,
                    })) || []),
                    ...(item.additives?.map((innerItem) => ({
                        key: innerItem.key || 'def_additives',
                        title: innerItem.title,
                        value: innerItem.value || innerItem.amount,
                    })) || []),
                ],
            })) || [],
    };
    return detectSplitConflict(checkYourShare(transformedDetails, yourSplitSettings));
};

const sortKeys = (keys: IKeyValue[], layout: ILayout[]): IKeyValue[] => {
    return layout.reduce<IKeyValue[]>((a, b, idx) => {
        const items = keys.filter((o) => o.key === b.key);
        if (items.length > 0) {
            a.push(
                ...items.map((o) => ({
                    ...o,
                    layout: b,
                    order: idx,
                })),
            );
        }
        return a;
    }, []);
};

export const parsePaymentDetails = (
    details: IPaymentDetailsResponse,
    config?: IGetPaymentDetailConfig,
): IPaymentDetails => {
    const res = transformKeys(details);
    const orderLayout =
        config?.item && config?.item.length > 0 ? config?.item : getDefaultOrderLayout(config?.cc || 'en');
    const defOrderLayout = orderLayout.find((o) => o.key === 'def_order');
    const billLayout = config?.bill && config.bill.length > 0 ? config.bill : getDefaultBillLayout(config?.cc || 'en');
    res._billItems = sortKeys(res._billItems, billLayout);
    res._orderItems = res._orderItems.map((o) => {
        return {
            ...o,
            item: {
                ...o.item,
                key: 'def_order',
                layout: defOrderLayout,
            },
            items: sortKeys(o.items, orderLayout),
        };
    });
    if (config?.bill || config?.item) {
        res._layoutInit = true;
    }
    return res;
};
