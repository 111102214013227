import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { format } from 'date-fns';

import Button from '@mui/material/Button';
import DateRange from '@mui/icons-material/DateRange';

import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import CustomModal from '@/components/CustomModal';

import { timeRangeTitles } from '../Form/hooks/useData';
import { type TimeRangeFormikProps, TimeRangeTypes } from '../Form/types';
import TimeRangeFormik from '../Formik';
import { getLocaleLang } from '../Form/utils';

type TimeModalProps = {
    customButton?: JSX.Element;
    noButton?: boolean;
    openState?: [boolean, Dispatch<SetStateAction<boolean>>];
} & TimeRangeFormikProps;

const TimeModal = ({ customButton, noButton, onConfirm, openState, options, ...rest }: TimeModalProps) => {
    const { t } = useTranslation('common');
    const { query } = useRouter();
    const lang = query.lang as string;
    const titles = timeRangeTitles(t);

    const [open, setOpen] = openState || useState<boolean>(false);
    const [buttonTitle, setButtonTitle] = useState(titles[options?.[0] || TimeRangeTypes.TODAY]);

    return (
        <>
            {!noButton &&
                (customButton || (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setOpen(true);
                            onPushEvent('date_range_drawer_opened');
                        }}
                        sx={{
                            textTransform: 'none',
                            borderRadius: '8px',
                            height: '40px',
                            width: '100%',
                        }}
                    >
                        {buttonTitle}
                        <DateRange sx={{ marginLeft: '4px' }} />
                    </Button>
                ))}
            <CustomModal
                noDialogActions
                open={open}
                title={t('Date Range')}
                actionText={t('Apply')}
                maxWidth="sm"
                onClose={() => setOpen(false)}
                keepMounted
            >
                <TimeRangeFormik
                    {...rest}
                    onConfirm={(value) => {
                        onConfirm?.(value);
                        const { type, from, to } = value;
                        let title = timeRangeTitles(t)[type];
                        if (type === 'custom' && from && to) {
                            title = `${format(new Date(from), 'LLLL dd', {
                                locale: getLocaleLang(lang),
                            })}-${format(new Date(to), 'LLLL dd', { locale: getLocaleLang(lang) })}`;
                        }
                        setButtonTitle(title);
                        setOpen(false);
                        onPushEvent(`user_use_filter_date_${value.type}`);
                    }}
                    onClose={() => {
                        onPushEvent('user_use_filter_date_cancel');
                        setOpen(false);
                    }}
                    options={options}
                />
            </CustomModal>
        </>
    );
};

export default TimeModal;
