import { useMemo } from 'react';
import { IQsrOrder, OrderPaymentStatusEnum } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';
import { IRestaurant, OrderPaymentMethodEnum } from '@/views/OrdersTableView/types';

import styles from './index.module.scss';

interface IProps {
    restaurant?: IRestaurant | null;
    order: IQsrOrder;
}

const QsrPaymentStatus = ({ order, restaurant }: IProps) => {
    const { t } = useTranslation('common');

    const [style, text] = useMemo(() => {
        switch (order.paymentStatus) {
            case OrderPaymentStatusEnum.Success:
                return [styles.success, t('paid')];
            default:
            case OrderPaymentStatusEnum.Failed:
            case OrderPaymentStatusEnum.Refunded:
            case OrderPaymentStatusEnum.Pending:
                return [styles.notPaid, t('not paid')];
        }
    }, [order.paymentStatus]);

    if (
        !order ||
        ![OrderPaymentMethodEnum.PayLater].includes(
            restaurant?.order_config?.paymentMethod || OrderPaymentMethodEnum.Default,
        )
    ) {
        return null;
    }

    return (
        <div className={classNames(styles.main, style)}>
            <div className={styles.status}>{text}</div>
        </div>
    );
};

export default QsrPaymentStatus;
