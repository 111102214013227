import { useMemo } from 'react';
import { IQsrOrder, OrderStatusEnum } from '@/views/QsrOrders/types';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';

import styles from './index.module.scss';

interface IProps {
    order: IQsrOrder;
}

const QsrOrderStatus = ({ order }: IProps) => {
    const { t } = useTranslation('common');

    const [style, text] = useMemo(() => {
        switch (order.orderStatus?.name) {
            default:
            case OrderStatusEnum.Pending:
                return [styles.pending, t('pending')];
            case OrderStatusEnum.Cancelled:
                return [styles.pending, t('cancelled')];
            case OrderStatusEnum.Approved:
                return [styles.approved, t('approved')];
            case OrderStatusEnum.Declined:
                return [styles.declined, t('declined')];
            case OrderStatusEnum.Failed:
                return [styles.declined, t('failed')];
            case OrderStatusEnum.Closed:
                return [styles.closed, t('closed')];
            case OrderStatusEnum.Scheduled:
                return [styles.scheduled, t('scheduled')];
        }
    }, [order.orderStatus?.name || '']);

    return (
        <div className={classNames(styles.main, style)}>
            <div className={styles.status}>{text}</div>
        </div>
    );
};

export default QsrOrderStatus;
