import axios from '@/config/axios';
import {
    IQsrCategoryParams,
    IQsrOrderItemSetStatusRequestParams,
    IQsrOrderSetPrintParams,
    IQsrOrderSetStatusRequestParams,
    IQsrOrdersRequestParams,
    IQsrOrdersResponse,
    IQsrProductParams,
} from '@/views/QsrOrders/types';
import { MenuCategoryResponse, MenuProductResponse } from '@/services/menuService/types';
import { clone, uniq, debounce, cloneDeep } from 'lodash';
import { transformError } from '.';

const endpoints = {
    QSR_ORDERS: 'vendor/qsr/orders/:restaurantId',
    SET_QSR_ORDER_STATUS: 'vendor/qsr/order/:restaurantId/:orderId/status',
    SET_QSR_ORDER_ITEM_STATUS: 'vendor/qsr/order/:restaurantId/:orderId/batchStatus/:uid',
    GET_QSR_PRODUCT_MAP: 'vendor/qsr/product/:restaurantId',
    GET_QSR_CATEGORY_MAP: 'vendor/qsr/category/:restaurantId',
    SET_PRINT: 'vendor/qsr/restaurant/:restaurant_id/order/:orderId/print',
};

interface IPromise {
    resolve: any;
    reject: any;
    ids: string[];
}

interface IRequest {
    promises: IPromise[];
    restaurantId: string;
    ids: string[];
}

class QsrOrdersService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new QsrOrdersService();
        }
        return this.instance;
    }

    private static instance: QsrOrdersService;

    private productMap: { [key: string]: MenuProductResponse } = {};

    private productRequests: IRequest[] = [];

    private categoryMap: { [key: string]: MenuCategoryResponse } = {};

    private categoryRequests: IRequest[] = [];

    // promise type will be changed to IQsrOrdersResponse
    public async getQsrOrders(params: IQsrOrdersRequestParams): Promise<IQsrOrdersResponse> {
        return axios
            .get(endpoints.QSR_ORDERS.replace(':restaurantId', params.restaurantId), {
                params: {
                    page: params.page,
                    limit: params.limit,
                    status: params.status,
                    statuses: params.statuses,
                    notInStatuses: params.notInStatuses,
                    startDate: params.startDate,
                    endDate: params.endDate,
                    refId: params.refId,
                },
            })
            .then((res) => {
                return res.data?.data;
            })
            .catch((err) => transformError(err));
    }

    // promise type will be changed to IQsrOrderSetStatusResponse
    public async setQsrOrderStatus(params: IQsrOrderSetStatusRequestParams): Promise<any> {
        return axios
            .put(
                endpoints.SET_QSR_ORDER_STATUS.replace(':restaurantId', params.restaurantId).replace(
                    ':orderId',
                    params.orderId,
                ),
                params,
            )
            .then((res) => res.data?.data)
            .catch((err) => {
                return transformError(err);
            });
    }

    public async setQsrOrderItemStatus(params: IQsrOrderItemSetStatusRequestParams): Promise<any> {
        return axios
            .put(
                endpoints.SET_QSR_ORDER_ITEM_STATUS.replace(':restaurantId', params.restaurantId)
                    .replace(':orderId', params.orderId)
                    .replace(':uid', params.uid),
                params,
            )
            .then((res) => res.data?.data)
            .catch((err) => {
                return transformError(err);
            });
    }

    private getProductHandler = () => {
        const requests = cloneDeep(this.productRequests);
        this.productRequests = [];
        requests.forEach((request) => {
            axios
                .post(endpoints.GET_QSR_PRODUCT_MAP.replace(':restaurantId', request.restaurantId), {
                    ids: request.ids,
                })
                .then((res) => res.data?.data || [])
                .then((res: MenuProductResponse[]) => {
                    const productMap = res.reduce<{ [key: string]: MenuProductResponse }>((a, c) => {
                        a[c.id] = c;
                        return a;
                    }, {});
                    request.promises.forEach((promise) => {
                        promise.resolve(
                            promise.ids.reduce<MenuProductResponse[]>((a, c) => {
                                const p = productMap[c];
                                if (p) {
                                    a.push(p);
                                }
                                return a;
                            }, []),
                        );
                    });
                })
                .catch((err) => {
                    request.promises.forEach((promise) => {
                        promise.reject(err);
                    });
                });
        });
    };

    private productDebounceFn = debounce(this.getProductHandler, 250);

    public async getProductMap(
        restaurantId: string,
        params: IQsrProductParams,
    ): Promise<{
        [key: string]: MenuProductResponse;
    }> {
        if (!params?.ids?.length) {
            return Promise.resolve({});
        }

        const { foundIds, notFoundIds } = params.ids.reduce<{ foundIds: string[]; notFoundIds: string[] }>(
            (a, id) => {
                if (this.productMap.hasOwnProperty(id)) {
                    a.foundIds.push(id);
                } else {
                    a.notFoundIds.push(id);
                }
                return a;
            },
            { foundIds: [], notFoundIds: [] },
        );
        if (foundIds.length === params.ids.length) {
            return Promise.resolve(this.getCacheProduct(foundIds));
        }

        return this.getProductByIds(restaurantId, {
            ids: notFoundIds,
        })
            .then((res) => {
                return res.reduce<{ [key: string]: MenuProductResponse }>((a, item) => {
                    this.productMap[item.id] = item;
                    a[item.id] = item;
                    return a;
                }, this.getCacheProduct(foundIds));
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    private getCacheProduct = (ids: string[]): { [key: string]: MenuProductResponse } => {
        if (ids.length === 0) {
            return {};
        }

        return ids.reduce<{ [key: string]: MenuProductResponse }>((a, id) => {
            a[id] = this.productMap[id];
            return a;
        }, {});
    };

    private getProductByIds(restaurantId: string, params: IQsrProductParams): Promise<MenuProductResponse[]> {
        return new Promise((resolve, reject) => {
            const idx = this.productRequests.findIndex((o) => o.restaurantId === restaurantId);
            if (idx === -1) {
                this.productRequests.push({
                    promises: [
                        {
                            reject,
                            resolve,
                            ids: params.ids,
                        },
                    ],
                    ids: clone(params.ids),
                    restaurantId,
                });
            } else {
                const req = this.productRequests[idx];
                req.ids = uniq([...req.ids, ...params.ids]);
                req.promises.push({
                    reject,
                    resolve,
                    ids: params.ids,
                });
            }
            this.productDebounceFn();
        });
    }

    private getCategoryHandler = () => {
        const requests = cloneDeep(this.categoryRequests);
        this.categoryRequests = [];
        requests.forEach((request) => {
            axios
                .post(endpoints.GET_QSR_CATEGORY_MAP.replace(':restaurantId', request.restaurantId), {
                    ids: request.ids,
                })
                .then((res) => res.data?.data || [])
                .then((res: MenuCategoryResponse[]) => {
                    const categoryMap = res.reduce<{ [key: string]: MenuCategoryResponse }>((a, c) => {
                        a[c.id] = c;
                        return a;
                    }, {});
                    request.promises.forEach((promise) => {
                        promise.resolve(
                            promise.ids.reduce<MenuCategoryResponse[]>((a, crr) => {
                                const c = categoryMap[crr];
                                if (c) {
                                    a.push(c);
                                }
                                return a;
                            }, []),
                        );
                    });
                })
                .catch((err) => {
                    request.promises.forEach((promise) => {
                        promise.reject(err);
                    });
                });
        });
    };

    private categoryDebounceFn = debounce(this.getCategoryHandler, 250);

    public async getCategoryMap(
        restaurantId: string,
        params: IQsrCategoryParams,
    ): Promise<{
        [key: string]: MenuCategoryResponse;
    }> {
        if (!params?.ids?.length) {
            return Promise.resolve({});
        }

        const { foundIds, notFoundIds } = params.ids.reduce<{ foundIds: string[]; notFoundIds: string[] }>(
            (a, id) => {
                if (this.categoryMap.hasOwnProperty(id)) {
                    a.foundIds.push(id);
                } else {
                    a.notFoundIds.push(id);
                }
                return a;
            },
            { foundIds: [], notFoundIds: [] },
        );
        if (foundIds.length === params.ids.length) {
            return Promise.resolve(this.getCacheCategory(foundIds));
        }

        return this.getCategoryByIds(restaurantId, {
            ids: notFoundIds,
        })
            .then((res) => {
                return res.reduce<{ [key: string]: MenuCategoryResponse }>((a, item) => {
                    this.categoryMap[item.id] = item;
                    a[item.id] = item;
                    return a;
                }, this.getCacheCategory(foundIds));
            })
            .catch((err) => {
                throw transformError(err);
            });
    }

    private getCacheCategory = (ids: string[]): { [key: string]: MenuCategoryResponse } => {
        if (ids.length === 0) {
            return {};
        }

        return ids.reduce<{ [key: string]: MenuCategoryResponse }>((a, id) => {
            a[id] = this.categoryMap[id];
            return a;
        }, {});
    };

    private getCategoryByIds(restaurantId: string, params: IQsrCategoryParams): Promise<MenuCategoryResponse[]> {
        return new Promise((resolve, reject) => {
            const idx = this.categoryRequests.findIndex((o) => o.restaurantId === restaurantId);
            if (idx === -1) {
                this.categoryRequests.push({
                    promises: [
                        {
                            reject,
                            resolve,
                            ids: params.ids,
                        },
                    ],
                    ids: clone(params.ids),
                    restaurantId,
                });
            } else {
                const req = this.categoryRequests[idx];
                req.ids = uniq([...req.ids, ...params.ids]);
                req.promises.push({
                    reject,
                    resolve,
                    ids: params.ids,
                });
            }
            this.categoryDebounceFn();
        });
    }

    public async setPrintTrigger(params: IQsrOrderSetPrintParams): Promise<any> {
        return axios
            .post(
                endpoints.SET_PRINT.replace(':restaurant_id', params.restaurantId).replace(':orderId', params.orderId),
                params,
            )
            .then((res) => res.data)
            .catch((err) => {
                return transformError(err);
            });
    }
}

export default QsrOrdersService;
