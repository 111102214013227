import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { timezoneKey } from '@/config/axios';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { ceil, floor, round } from 'lodash';
import { OrderPriceRoundingModeEnum } from '@/views/OrdersTableView/types';

require('dayjs/locale/ja');
require('dayjs/locale/pt');

enum ReportType {
    BRAND = 'brand',
    RESTAURANT = 'restaurant',
}

const getDeviceType = () => {
    if (window.innerWidth <= 768) {
        return 'mobile';
    }
    if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        return 'tablet';
    }
    return 'desktop';
};

const commafy = (num: any, fix?: number) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(num)) {
        num = 0.0;
    }
    fix = fix || 0;
    if (num === null) {
        return '0';
    }
    if (!Number.isInteger(num)) {
        num = Number(num).toFixed(fix);
    } else {
        num = String(num);
    }

    return num.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

const localeCurrencyFormat = (currencyCode: string, amount: any) => {
    if (currencyCode === 'brl') {
        const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
        return formatter.format(amount);
    }

    if (currencyCode === 'jp') {
        const formatter = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });
        return formatter.format(amount);
    }
    return amount;
};

export const kRound = (
    amount: string | number,
    precision: number,
    rounding: OrderPriceRoundingModeEnum = OrderPriceRoundingModeEnum.Round,
): string => {
    if (typeof amount === 'string') {
        amount = Number(amount);
    }
    switch (rounding) {
        default:
        case OrderPriceRoundingModeEnum.Round:
            return round(amount, precision).toFixed(precision);
        case OrderPriceRoundingModeEnum.Floor:
            return floor(amount, precision).toFixed(precision);
        case OrderPriceRoundingModeEnum.Ceil:
            return ceil(amount, precision).toFixed(precision);
    }
};

const getCurrencyWithAmount = (
    amount: any,
    currencySymbol: string | null,
    currencyCode: string,
    withCommafy?: boolean,
    rounding?: OrderPriceRoundingModeEnum,
) => {
    // if (typeof amount === 'string') {
    //     return amount;
    // }
    currencyCode = currencyCode?.toLowerCase();

    const cp = getCurrencyPrecision(currencyCode);
    if (rounding) {
        return `${currencySymbol || ''} ${kRound(amount, cp, rounding)}`;
    }
    if (currencyCode === 'brl' || currencyCode === 'jp') {
        return localeCurrencyFormat(currencyCode, amount);
    }

    const str = Number(amount).toFixed(cp);
    return `${currencySymbol || ''} ${withCommafy ? commafy(str, cp) : str}`;
};

const getTotalByKey = (data: Array<any>, key: string, confFn?: (d: any) => boolean): number => {
    if (confFn) {
        return data.reduce((a, b) => {
            if (confFn(b)) {
                return a + (Number(b[key] || 0) || 0);
            }
            return a;
        }, 0);
    }
    return data.reduce((a, b) => a + (Number(b[key] || 0) || 0), 0);
};

export const getManyTotalByKey = (
    data: Array<any>,
    keys: string[],
    confFns?: Array<undefined | ((d: any) => boolean)>,
): number[] => {
    const initArray = new Array(keys.length).fill(0);
    const accFn = (a: number, b: any, key: string, fn?: (d: any) => boolean) => {
        if (fn) {
            if (fn(b)) {
                return a + (Number(b[key] || 0) || 0);
            }
            return a;
        }
        return a + (Number(b[key] || 0) || 0);
    };
    return data.reduce((a, b) => {
        keys.forEach((key, index) => {
            a[index] = accFn(a[index], b, key, confFns?.[index]);
        });
        return a;
    }, initArray);
};

export const getTotalAmount = (data: Array<any>) => {
    const tipAmount = getTotalByKey(data, 'tip_amount');
    const paidAmount = getTotalByKey(data, 'paid_amount');
    const customerCommission = getTotalByKey(data, 'customer_commission');
    return tipAmount + paidAmount - customerCommission;
};

export const getTotalPaidAmount = (data: Array<any>) => {
    return getTotalByKey(data, 'paid_amount');
};

const timezoneConfig: {
    zone: string | null;
} = {
    zone: '',
};

export const setTimezone = (val: string) => {
    localStorage.setItem(timezoneKey, val);
    timezoneConfig.zone = val;
};

export const getTimezone = () => {
    if (timezoneConfig.zone) {
        return timezoneConfig.zone;
    }
    timezoneConfig.zone = localStorage.getItem(timezoneKey);
    return timezoneConfig.zone || '';
};

const covertUtcToRestaurantDateTime = (dateTime: Date | string | null | undefined, format: string) => {
    dayjs.extend(utc);
    dayjs.extend(tz);

    const timezone = getTimezone();
    if (timezone !== '') {
        return dayjs(dateTime).tz(timezone).format(format);
    }
    return dayjs(dateTime).format(format);
};

const setDateLocale = (locale: string) => {
    dayjs.locale(locale);
};

const getGstType = (countryCode: string) => {
    switch (countryCode) {
        case 'au':
            return 'ABN';
        case 'ae':
            return 'TRN';
        default:
            return 'GST Reg. No';
    }
};

const getGstCurrencyType = (currency: string | null) => {
    switch (currency) {
        case 'aud':
            return 'GST';
        case 'aed':
            return 'TRN';
        default:
            return 'VAT';
    }
};

export {
    getCurrencyWithAmount,
    commafy,
    getTotalByKey,
    covertUtcToRestaurantDateTime,
    setDateLocale,
    ReportType,
    getDeviceType,
    getGstType,
    getGstCurrencyType,
};
